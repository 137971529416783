<template>
  <div
    :id="widgetId"
    :style="{
      height: this.widgetHeight + 'px',
      width: this.widgetWidth + 'px',
      paddingTop: '2px',
      background: 'var(--widgetBg)',
      transition: 'var(--transition)',
      'border-radius': '10px',
    }"
    class="killsheet_main"
    @contextmenu="this.setContextIdWidget"
  >
    <div
      class="h-full overflow-scroll"
      :style="{
        background: 'var(--widgetBg)',
        color: darkDark !== 'white' ? '#fff' : '#222',
        transition: 'var(--transition)',
      }"
    >
      <div class="flex justify-end px-3">
        <div class="float-right ptop flex items-center">
          <div>
            <button
              class="refresh text-xxxxxs font-semibold uppercase relative"
              v-on:click="toggleInclinationDataNull"
            >
              Inclination
            </button>
          </div>
          <div class="px-3">
            <button
              class="refresh text-xxxxxs font-semibold uppercase relative"
              v-on:click="refresh"
            >
              Refresh
            </button>
          </div>
          <div class="pr-3">
            <button
              class="refresh text-xxxxxs font-semibold uppercase relative"
              v-on:click="printData"
            >
              Print
            </button>
          </div>
          <div class="ptop">
            <input
              type="checkbox"
              class="checkboxs hidden"
              id="checkboxs"
              v-model="checked"
              v-on:click="toggleVerticalHorizontal"
            />
            <label for="checkboxs" class="checkbox-labels">
              <svg
                width="8.025"
                height="10.5"
                viewBox="0 0 8.025 10.5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <!-- <g
                  id="svgGroup"
                  stroke-linecap="round"
                  fill-rule="evenodd"
                  font-size="9pt"
                  stroke="#61cba1"
                  stroke-width="0.25mm"
                  fill="#03e9f4"
                  style="stroke: #03e9f4; stroke-width: 0.25mm; fill: #03e9f4"
                > -->
                <g
                  id="svgGroup"
                  stroke-linecap="round"
                  fill-rule="evenodd"
                  font-size="9pt"
                  stroke="#61cba1"
                  stroke-width="0.25mm"
                  fill="#03e9f4"
                  style="stroke: var(--activeTextColor); stroke-width: 0.25mm; fill: var(--activeTextColor)"
                >
                  <path
                    d="M 1.425 10.5 L 0 10.5 L 0 0 L 1.425 0 L 1.425 4.485 L 6.6 4.485 L 6.6 0 L 8.025 0 L 8.025 10.5 L 6.6 10.5 L 6.6 5.715 L 1.425 5.715 L 1.425 10.5 Z"
                    vector-effect="non-scaling-stroke"
                  />
                </g>
              </svg>
              <svg
                width="9.3"
                height="10.665"
                viewBox="0 0 9.3 10.665"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="svgGroup"
                  stroke-linecap="round"
                  fill-rule="evenodd"
                  font-size="9pt"
                  stroke="#61cba1"
                  stroke-width="0.25mm"
                  fill="#03e9f4"
                  style="stroke: var(--activeTextColor); stroke-width: 0.25mm; fill: var(--activeTextColor)"
                >
                  <path
                    d="M 5.46 10.665 L 3.855 10.665 L 0 0.54 L 1.395 0 L 4.695 9.105 L 8.01 0.045 L 9.3 0.54 L 5.46 10.665 Z"
                    vector-effect="non-scaling-stroke"
                  />
                </g>
              </svg>

              <span class="ball"></span>
            </label>
          </div>
        </div>
      </div>
      <div
        class="flex items-center incdata"
        :style="{
          height: this.widgetHeight + 'px',
          width: this.widgetWidth + 'px',
        }"
        v-show="!incldatanull"
      >
        <div class="dispop px-5 py-2">
          <div class="p-5">
            <h2 v-show="inclination==''" class="font-semibold pb-3 pt-2 text-xxs text-center color-red">
              Unable to Understand The Well Profile
            </h2>
            <div class="mb-2 px-4 flex justify-between items-center flex-col">
              <label class="block text-xxxxs font-medium textColorClass pb-3">
                Please Provide the Inclination Data
              </label>
              <input
                name=""
                v-model="inclination"
                onkeydown="return event.keyCode !== 69"
                class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                id="leak"
                type="number"
                placeholder="Enter value"
                :style="{
                  background: darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                  color: darkDark !== 'white' ? '#fff' : '#222',
                  transition: 'var(--transition)',
                }"
              />
            </div>
            <div class="mb-2 px-1 pt-3 flex flex-col">
              <p class="text-xxxs">Inclination &lt; 10 : Vertical</p>
              <p class="text-xxxs">Inclination &gt;= 10 : Horizontal</p>
            </div>
            <div class="mb-2 px-4 flex justify-end items-center">
              <button
                v-on:click="dispok"
                class="dispokbtn text-xxs mr-5 font-semibold px-5 py-2"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal for confirm box to capture screen starts -->
      <div class="sendMail_modal h-full" v-show="isSupportScreenshotShowing">
        <div class="Child_Of_sendMail_modal">
          <div
            class="p-4 py-2 flex justify-end items-center shadow"
            style="height: 42px"
          >
            <button
              v-on:click="close"
              class="text-sm font-normal uppercase text-red_"
              style="color: #cf634b"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div
            class="w-full flex px-5 py-3 overflow-auto"
            style="gap: 30px; height: calc(100% - 42px)"
          >
            <div style="width: 80%; margin: auto">
              <canvas id="image_to_show" class="hidden"></canvas>
              <img
                :src="capturedImage"
                v-if="capturedImage"
                style="max-height: 80%"
              />
              <div class="mb-2 px-4 pt-3 flex justify-end items-center">
                <button
                  v-on:click="download"
                  class="dispokbtn text-xxs mr-5 font-semibold px-5 py-2"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal for confirm box to capture screen ends -->
      <Vertical v-show="!checked" :checked="checked" :conversionFactors="conversionFactors" :id="id" :displayId="displayId" :widgetId="widgetId" :widgetdisppopHeight="widgetdisppopHeight" :widgetdisppopWidth="widgetdisppopWidth" :widgetpopHeight="widgetpopHeight" :widgetpopWidth="widgetpopWidth" :well_Id="wellId" :wellbore_Id="wellboreId" :inclination_p="inclination" :incl_data_prop="incl_data" :isReplay="isReplay" @checked_changed="(val)=>checked=val" />
      <!-- Vertical Kill Sheet Design -->
      <!-- <div
        class="grid grid-cols-3 gap-2 capture"
        v-if="!checked"
        :style="{
          background: 'var(--widgetBg)',
          color: darkDark !== 'white' ? '#fff' : '#222',
          transition: 'var(--transition)',
        }"
      >
        <div class="col-span-2">
          <div class="pl-2 grid grid-cols-2 gap-2">
            <div class="px-2 grid grid-cols-2">
              <div class="flex col-span-2 justify-between">
                <div class="flex text-xxs justify-between content-center">
                  <h6 class="font-medium pt-px">Well Name</h6>
                  <p class="text-xs px-3 font-semibold colr">
                    {{ this.wellboreId }}
                  </p>
                </div>
                <div class="flex text-xxs justify-between content-center">
                  <h6 class="font-medium pt-px">Date</h6>
                  <p class="text-xs px-3 font-semibold">{{ time_show }}</p>
                </div>
              </div>
              <div class="col-span-2">
                <div class="grid grid-cols-3">
                  <div class="grid grid-cols-1">
                    <div class="pt-3 px-1">
                      <h4 class="font-semibold pb-1 pl-1 text-xxs">
                        User Inputs
                      </h4>
                      <div class="mb-2 pl-2 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          LOT Pressure
                        </label>
                        <div class="flex items-center">
                          <input
                            v-model="vuserinp.vuserdata.vlotp"
                            @change="lotpressure('lot_pressure', $event)"
                            class="shadow appearance-none border rounded-sm text-xxxs py-1 px-2 w-14 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="leak"
                            type="text"
                            placeholder="Enter value"
                            :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }"
                          />
                          <div class="killsheet_widget_unit_select ml-1">
                            <UnitSelect
                              :display-id="displayId"
                              :widget-id="widgetId"
                              :mnemonic="'dmea'"
                              :pt="'pt-0'"
                              :category="'Pressure'"
                              :hide-label="true"
                              :is-custom-events="true"
                              :reverse="true"
                              :standaredUnit="
                                mappingDetails.lot_pressure.standard_units
                              "
                              :isMetrics="isWellMetrics"
                              :selected-val="
                                conversionFactors?.lot_pressure?.unit &&
                                conversionFactors?.lot_pressure?.unit != ''
                                  ? conversionFactors.lot_pressure.unit
                                  : isWellMetrics
                                  ? mappingDetails.lot_pressure.METRIC
                                  : mappingDetails.lot_pressure.IMPERIAL
                              "
                              @change_unit_handler="
                                (val) => changeUnitHandler('lot_pressure', val)
                              "
                              :key="refreshUnitkey"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="mb-2 pl-2 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          M.Wt At Test
                        </label>
                        <div class="flex items-center">
                          <input
                            v-model="vuserinp.vuserdata.rho_test"
                            @change="lotpressure('mwt_at_test', $event)"
                            class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id=""
                            type="text"
                            placeholder="Enter value"
                            :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }"
                          />
                          <div class="killsheet_widget_unit_select ml-1">
                            <UnitSelect
                              :display-id="displayId"
                              :widget-id="widgetId"
                              :mnemonic="'dmea'"
                              :pt="'pt-0'"
                              :category="'MW-Density gradients'"
                              :hide-label="true"
                              :is-custom-events="true"
                              :reverse="true"
                              :standaredUnit="
                                mappingDetails.mwt_at_test.standard_units
                              "
                              :isMetrics="isWellMetrics"
                              :selected-val="
                                conversionFactors?.mwt_at_test?.unit &&
                                conversionFactors?.mwt_at_test?.unit != ''
                                  ? conversionFactors.mwt_at_test.unit
                                  : isWellMetrics
                                  ? mappingDetails.mwt_at_test.METRIC
                                  : mappingDetails.mwt_at_test.IMPERIAL
                              "
                              @change_unit_handler="
                                (val) => changeUnitHandler('mwt_at_test', val)
                              "
                              :key="refreshUnitkey"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="px-1">
                      <h4 class="font-semibold pb-1 text-xxs">
                        Casing & Shoe Data
                      </h4>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Size ({{
                            isWellMetrics
                              ? mappingDetails.casing_and_data_size.METRIC
                              : mappingDetails.casing_and_data_size.IMPERIAL
                          }})
                        </label>
                        <input
                          v-model="idia"
                          class="ml-2 shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Casing MD ({{
                            isWellMetrics
                              ? mappingDetails.casing_and_data_casing_md.METRIC
                              : mappingDetails.casing_and_data_casing_md
                                  .IMPERIAL
                          }})
                        </label>
                        <input
                          v-model="md"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Casing TVD ({{
                            isWellMetrics
                              ? mappingDetails.casing_and_data_casing_tvd.METRIC
                              : mappingDetails.casing_and_data_casing_tvd
                                  .IMPERIAL
                          }})
                        </label>
                        <input
                          v-model="tvd"
                          @change="lotpressure"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-1">
                    <div class="pt-3 px-1">
                      <h4 class="font-semibold pb-1 pl-1 text-xxs">
                        Drilling Fluid Data
                      </h4>
                      <div
                        class="mb-2 pt-1 pl-1 flex justify-between items-center"
                      >
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Density
                        </label>

                        <div class="flex items-center">
                          <input
                            v-model="vuserinp.vuserdata.rho"
                            @change="lotpressure('density', $event)"
                            class="shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="density"
                            type="text"
                            placeholder="Enter value"
                            :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }"
                          />
                          <div class="killsheet_widget_unit_select ml-1">
                            <UnitSelect
                              :display-id="displayId"
                              :widget-id="widgetId"
                              :mnemonic="'dmea'"
                              :pt="'pt-0'"
                              :category="'MW-Density gradients'"
                              :hide-label="true"
                              :is-custom-events="true"
                              :reverse="true"
                              :standaredUnit="
                                mappingDetails.density.standard_units
                              "
                              :isMetrics="isWellMetrics"
                              :selected-val="
                                conversionFactors?.density?.unit &&
                                conversionFactors?.density?.unit != ''
                                  ? conversionFactors.density.unit
                                  : isWellMetrics
                                  ? mappingDetails.density.METRIC
                                  : mappingDetails.density.IMPERIAL
                              "
                              @change_unit_handler="
                                (val) => changeUnitHandler('density', val)
                              "
                              :key="refreshUnitkey"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          v-if="isWellMetrics"
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Gradient (kpa/m)
                        </label>
                        <label
                          v-else
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Gradient (psi/ft)
                        </label>
                        <input
                          :disabled="shouldDisable"
                          :value="
                            isWellMetrics
                              ? vuserinp.vuserdata.vgradient * 22.621
                              : vuserinp.vuserdata.vgradient
                          "
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </div>
                    </div>
                    <div class="px-1">
                      <h4 class="font-semibold pb-1 text-xxs">Hole Data</h4>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Size ({{
                            isWellMetrics
                              ? mappingDetails.hole_data_size.METRIC
                              : mappingDetails.hole_data_size.IMPERIAL
                          }})
                        </label>
                        <input
                          v-model="bit_size"
                          class="shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Hole Depth ({{
                            isWellMetrics
                              ? mappingDetails.hole_data_hole_depth.METRIC
                              : mappingDetails.hole_data_hole_depth.IMPERIAL
                          }})
                        </label>
                        <input
                          v-model="dmea"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          placeholder="Enter value"
                        />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Hole TVD ({{
                            isWellMetrics
                              ? mappingDetails.hole_data_tvd.METRIC
                              : mappingDetails.hole_data_tvd.IMPERIAL
                          }})
                        </label>
                        <input
                          v-model="vuserinp.vuserdata.vbittvd"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Bit Depth ({{
                            isWellMetrics
                              ? mappingDetails.hole_data_bit_depth.METRIC
                              : mappingDetails.hole_data_bit_depth.IMPERIAL
                          }})
                        </label>
                        <input
                          v-model="dbtm"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          placeholder="Enter value"
                        />
                      </div>

                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label
                          class="block text-xxxs font-medium"
                          for="password"
                        >
                          Bit TVD ({{
                            isWellMetrics
                              ? mappingDetails.hole_data_bit_tvd.METRIC
                              : mappingDetails.hole_data_bit_tvd.IMPERIAL
                          }})
                        </label>
                        <input
                          v-model="vuserinp.vuserdata.vholetvd"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-1">
                    <svg height="280" width="133">
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 50 45 l 0 180 l 45 0 l 0 -168"
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="#aaa"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 60 200 l -10 25 l 45 0 l -10 -25 l -25 0"
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="#aaa"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 60 169 l 0 30 l 25 0 l 0 -30 l -25 0"
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="#aaa"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 67 40 l 0 130 l 10 0 l 0 -130 l -10 0"
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 72 30 l 0 10"
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 65 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 50 30 l 0 20 l 45 0 l 0 -20"
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 95 50 l 15 0 "
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 95 57 l 20 0 "
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 110 30 l 0 20 "
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 115 30 l 0 28 "
                        ></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          :stroke="'var(--textColor)'"
                          stroke-width="1.5px"
                          d="M 106 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "
                        ></path>
                      </g>
                      <g>
                        <text
                          x="75"
                          y="238"
                          text-anchor="middle"
                          fill="var(--textColor)"
                          font-size="8"
                        >
                          Bit Depth {{ dbtm }} ({{
                            isWellMetrics
                              ? mappingDetails.hole_data_bit_depth.METRIC
                              : mappingDetails.hole_data_bit_depth.IMPERIAL
                          }})
                        </text>
                      </g>
                      <g>
                        <text
                          x="40"
                          y="10"
                          text-anchor="middle"
                          fill="var(--textColor)"
                          font-size="8"
                        >
                          SIDP {{ vuserinp.vuserdata.sidpp }} ({{
                            conversionFactors?.lot_pressure?.from_unit &&
                            conversionFactors?.lot_pressure?.from_unit != ""
                              ? conversionFactors.lot_pressure.from_unit
                              : isWellMetrics
                              ? mappingDetails.lot_pressure.METRIC
                              : mappingDetails.lot_pressure.IMPERIAL
                          }})
                        </text>
                      </g>
                      <g>
                        <text
                          transform="rotate(-90)"
                          x="-130"
                          y="110"
                          text-anchor="middle"
                          fill="var(--textColor)"
                          font-size="8"
                        >
                          SICP {{ vuserinp.vuserdata.sicp }} ({{
                            conversionFactors?.lot_pressure_horizantal?.from_unit &&
                            conversionFactors?.lot_pressure_horizantal?.from_unit != ""
                              ? conversionFactors.lot_pressure_horizantal.from_unit
                              : isWellMetrics
                              ? mappingHorzDetails.lot_pressure_horizantal.METRIC
                              : mappingHorzDetails.lot_pressure_horizantal.IMPERIAL
                          }})
                        </text>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="pb-1 col-span-2">
                <h4 class="font-semibold pb-1 text-xxs">
                  Pre Recorded Volume Data (Drill String Volume)
                </h4>
                <div class="pt-0.5 pb-0.5 pumpdiv">
                  <table
                    class="border-collapse border pump w-full rounded-sm"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <tr>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Component
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <div class="flex items-center justify-center">
                          <div>Length</div>
                          <div class="killsheet_widget_unit_select_table ml-1">
                            <UnitSelect
                              :display-id="displayId"
                              :widget-id="widgetId"
                              :mnemonic="'dmea'"
                              :pt="'pt-0'"
                              :category="'Length'"
                              :hide-label="true"
                              :is-custom-events="true"
                              :reverse="true"
                              :standaredUnit="
                                mappingDetails.pre_recorded_volume_data_length
                                  .standard_units
                              "
                              :isMetrics="isWellMetrics"
                              :selected-val="
                                conversionFactors
                                  ?.pre_recorded_volume_data_length?.unit &&
                                conversionFactors
                                  ?.pre_recorded_volume_data_length?.unit != ''
                                  ? conversionFactors
                                      .pre_recorded_volume_data_length.unit
                                  : isWellMetrics
                                  ? mappingDetails
                                      .pre_recorded_volume_data_length.METRIC
                                  : mappingDetails
                                      .pre_recorded_volume_data_length.IMPERIAL
                              "
                              @change_unit_handler="
                                (val) =>
                                  changeUnitHandler(
                                    'pre_recorded_volume_data_length',
                                    val
                                  )
                              "
                              :key="refreshUnitkey"
                            />
                          </div>
                        </div>
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <div class="flex items-center justify-center">
                          <div>Capacity</div>
                          <div class="killsheet_widget_unit_select_table ml-1">
                            <UnitSelect
                              :display-id="displayId"
                              :widget-id="widgetId"
                              :mnemonic="'dmea'"
                              :pt="'pt-0'"
                              :category="'Displacement'"
                              :hide-label="true"
                              :is-custom-events="true"
                              :reverse="true"
                              :standaredUnit="
                                mappingDetails.pre_recorded_volume_data_capacity
                                  .standard_units
                              "
                              :isMetrics="isWellMetrics"
                              :selected-val="
                                conversionFactors
                                  ?.pre_recorded_volume_data_capacity?.unit &&
                                conversionFactors
                                  ?.pre_recorded_volume_data_capacity?.unit !=
                                  ''
                                  ? conversionFactors
                                      .pre_recorded_volume_data_capacity.unit
                                  : isWellMetrics
                                  ? mappingDetails
                                      .pre_recorded_volume_data_capacity.METRIC
                                  : mappingDetails
                                      .pre_recorded_volume_data_capacity
                                      .IMPERIAL
                              "
                              @change_unit_handler="
                                (val) =>
                                  changeUnitHandler(
                                    'pre_recorded_volume_data_capacity',
                                    val
                                  )
                              "
                              :key="refreshUnitkey"
                            />
                          </div>
                        </div>
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <div class="flex items-center justify-center">
                          <div>
                            Volume ({{
                              getVolumeUnits(
                                conversionFactors
                                  ?.pre_recorded_volume_data_length
                                  ?.from_unit &&
                                  conversionFactors
                                    ?.pre_recorded_volume_data_length
                                    ?.from_unit != ""
                                  ? conversionFactors
                                      .pre_recorded_volume_data_length.from_unit
                                  : isWellMetrics
                                  ? mappingDetails
                                      .pre_recorded_volume_data_length.METRIC
                                  : mappingDetails
                                      .pre_recorded_volume_data_length.IMPERIAL,
                                conversionFactors
                                  ?.pre_recorded_volume_data_capacity
                                  ?.from_unit &&
                                  conversionFactors
                                    ?.pre_recorded_volume_data_capacity
                                    ?.from_unit != ""
                                  ? conversionFactors
                                      .pre_recorded_volume_data_capacity
                                      .from_unit
                                  : isWellMetrics
                                  ? mappingDetails
                                      .pre_recorded_volume_data_capacity.METRIC
                                  : mappingDetails
                                      .pre_recorded_volume_data_capacity
                                      .IMPERIAL
                              )?.unit || ""
                            }})
                          </div>
                        </div>
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Pump Strokes (stks)
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Time (min)
                      </th>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Drill Pipe
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dplength"
                          @change="storeOriginalvalues('dplength', $event)"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dpcapacity"
                          @change="storeOriginalvalues('dpcapacity', $event)"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dpvolume"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dpstrokes"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dptime"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        HWDP
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="hwdplength"
                          @change="storeOriginalvalues('hwdplength', $event)"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="hwdpcapacity"
                          @change="storeOriginalvalues('hwdpcapacity', $event)"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="hwdpvolume"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="hwdpstrokes"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="hwdptime"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        DC 2
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc2length"
                          @change="storeOriginalvalues('dc2length', $event)"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc2capacity"
                          @change="storeOriginalvalues('dc2capacity', $event)"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc2volume"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc2strokes"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc2time"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        DC 1
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc1length"
                          @change="storeOriginalvalues('dc1length', $event)"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc1capacity"
                          @change="storeOriginalvalues('dc1capacity', $event)"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc1volume"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc1strokes"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc1time"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Total
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="totallength"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >

                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="drillstringvolumeV"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="totalstrokes"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="totaltime"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="pb-1 col-span-2">
                <h4 class="font-semibold pb-1 text-xxs">
                  Casing Annular Volume
                </h4>
                <div class="pt-0.5 pb-2 pumpdiv">
                  <table
                    class="border-collapse border pump w-full rounded-sm"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <tr>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Component
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <div class="flex items-center justify-center">
                          <div>Length</div>
                          <div class="killsheet_widget_unit_select_table ml-1">
                            <UnitSelect
                              :display-id="displayId"
                              :widget-id="widgetId"
                              :mnemonic="'dmea'"
                              :pt="'pt-0'"
                              :category="'Length'"
                              :hide-label="true"
                              :is-custom-events="true"
                              :reverse="true"
                              :standaredUnit="
                                mappingDetails.casing_annular_volume_length
                                  .standard_units
                              "
                              :isMetrics="isWellMetrics"
                              :selected-val="
                                conversionFactors?.casing_annular_volume_length
                                  ?.unit &&
                                conversionFactors?.casing_annular_volume_length
                                  ?.unit != ''
                                  ? conversionFactors
                                      .casing_annular_volume_length.unit
                                  : isWellMetrics
                                  ? mappingDetails.casing_annular_volume_length
                                      .METRIC
                                  : mappingDetails.casing_annular_volume_length
                                      .IMPERIAL
                              "
                              @change_unit_handler="
                                (val) =>
                                  changeUnitHandler(
                                    'casing_annular_volume_length',
                                    val
                                  )
                              "
                              :key="refreshUnitkey"
                            />
                          </div>
                        </div>
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <div class="flex items-center justify-center">
                          <div>Capacity</div>
                          <div class="killsheet_widget_unit_select_table ml-1">
                            <UnitSelect
                              :display-id="displayId"
                              :widget-id="widgetId"
                              :mnemonic="'dmea'"
                              :pt="'pt-0'"
                              :category="'Displacement'"
                              :hide-label="true"
                              :is-custom-events="true"
                              :reverse="true"
                              :standaredUnit="
                                mappingDetails.casing_annular_volume_capacity
                                  .standard_units
                              "
                              :isMetrics="isWellMetrics"
                              :selected-val="
                                conversionFactors
                                  ?.casing_annular_volume_capacity?.unit &&
                                conversionFactors
                                  ?.casing_annular_volume_capacity?.unit != ''
                                  ? conversionFactors
                                      .casing_annular_volume_capacity.unit
                                  : isWellMetrics
                                  ? mappingDetails
                                      .casing_annular_volume_capacity.METRIC
                                  : mappingDetails
                                      .casing_annular_volume_capacity.IMPERIAL
                              "
                              @change_unit_handler="
                                (val) =>
                                  changeUnitHandler(
                                    'casing_annular_volume_capacity',
                                    val
                                  )
                              "
                              :key="refreshUnitkey"
                            />
                          </div>
                        </div>
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <div class="flex items-center justify-center">
                          <div>
                            Volume({{
                              getVolumeUnits(
                                conversionFactors?.casing_annular_volume_length
                                  ?.from_unit &&
                                  conversionFactors
                                    ?.casing_annular_volume_length?.from_unit !=
                                    ""
                                  ? conversionFactors
                                      .casing_annular_volume_length.from_unit
                                  : isWellMetrics
                                  ? mappingDetails.casing_annular_volume_length
                                      .METRIC
                                  : mappingDetails.casing_annular_volume_length
                                      .IMPERIAL,
                                conversionFactors
                                  ?.casing_annular_volume_capacity?.from_unit &&
                                  conversionFactors
                                    ?.casing_annular_volume_capacity
                                    ?.from_unit != ""
                                  ? conversionFactors
                                      .casing_annular_volume_capacity.from_unit
                                  : isWellMetrics
                                  ? mappingDetails
                                      .casing_annular_volume_capacity.METRIC
                                  : mappingDetails
                                      .casing_annular_volume_capacity.IMPERIAL
                              )?.unit || ""
                            }})
                          </div>
                        </div>
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Pump Strokes (stks)
                      </th>
                      <th
                        class="border text-xxxs px-1 font-medium"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Time (min)
                      </th>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 uppercase font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        DP X Casing
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casingdplength"
                          @change="
                            storeOriginalvalues('casingdplength', $event)
                          "
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casingdpannularcapacity"
                          @change="
                            storeOriginalvalues(
                              'casingdpannularcapacity',
                              $event
                            )
                          "
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="cassed_hole_vol"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casingdpstrokes"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casingdptime"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 uppercase font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        HWDP X Casing
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casinghwdplength"
                          @change="
                            storeOriginalvalues('casinghwdplength', $event)
                          "
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casinghwdpannularcapacity"
                          @change="
                            storeOriginalvalues(
                              'casinghwdpannularcapacity',
                              $event
                            )
                          "
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casinghwdpannularvolume"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          v-model="casinghwdpannularstrokes"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          v-model="casinghwdpannulartime"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 uppercase font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        DC 2 X Casing
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casingdc2length"
                          @change="
                            storeOriginalvalues('casingdc2length', $event)
                          "
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casingdc2annularcapacity"
                          @change="
                            storeOriginalvalues(
                              'casingdc2annularcapacity',
                              $event
                            )
                          "
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc2annularvoulume"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dc2annularstrokes"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          v-model="dc2annulartime"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 uppercase font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        DC 1 X Casing
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casingdc1length"
                          @change="
                            storeOriginalvalues('casingdc1length', $event)
                          "
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="casingdc1annularcapacity"
                          @change="
                            storeOriginalvalues(
                              'casingdc1annularcapacity',
                              $event
                            )
                          "
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="dcannularvoulume"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          v-model="dcannularstrokes"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          v-model="dcannulartime"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="border text-xxxs px-1 uppercase font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        Total
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="totalcasingdplength"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="totalcasingdpvolume"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="total_casingdpstrokes"
                        />
                      </td>
                      <td
                        class="border text-xxxs px-1 font-light text-sx"
                        :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }"
                      >
                        <input
                          :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id=""
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }"
                          v-model="total_casingdptime"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="py-1">
              <h4 class="font-semibold pb-1 text-xxs">Open Hole Volume</h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table
                  class="border-collapse border pump w-full rounded-sm"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <tr>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Component
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <div class="flex items-center justify-center">
                        <div>Length</div>
                        <div class="killsheet_widget_unit_select_table ml-1">
                          <UnitSelect
                            :display-id="displayId"
                            :widget-id="widgetId"
                            :mnemonic="'dmea'"
                            :pt="'pt-0'"
                            :category="'Length'"
                            :hide-label="true"
                            :is-custom-events="true"
                            :reverse="true"
                            :standaredUnit="
                              mappingDetails.open_hole_volume_length
                                .standard_units
                            "
                            :isMetrics="isWellMetrics"
                            :selected-val="
                              conversionFactors?.open_hole_volume_length
                                ?.unit &&
                              conversionFactors?.open_hole_volume_length
                                ?.unit != ''
                                ? conversionFactors.open_hole_volume_length.unit
                                : isWellMetrics
                                ? mappingDetails.open_hole_volume_length.METRIC
                                : mappingDetails.open_hole_volume_length
                                    .IMPERIAL
                            "
                            @change_unit_handler="
                              (val) =>
                                changeUnitHandler(
                                  'open_hole_volume_length',
                                  val
                                )
                            "
                            :key="refreshUnitkey"
                          />
                        </div>
                      </div>
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <div class="flex items-center justify-center">
                        <div>Capacity</div>
                        <div class="killsheet_widget_unit_select_table ml-1">
                          <UnitSelect
                            :display-id="displayId"
                            :widget-id="widgetId"
                            :mnemonic="'dmea'"
                            :pt="'pt-0'"
                            :category="'Displacement'"
                            :hide-label="true"
                            :is-custom-events="true"
                            :reverse="true"
                            :standaredUnit="
                              mappingDetails.open_hole_volume_capacity
                                .standard_units
                            "
                            :isMetrics="isWellMetrics"
                            :selected-val="
                              conversionFactors?.open_hole_volume_capacity
                                ?.unit &&
                              conversionFactors?.open_hole_volume_capacity
                                ?.unit != ''
                                ? conversionFactors.open_hole_volume_capacity
                                    .unit
                                : isWellMetrics
                                ? mappingDetails.open_hole_volume_capacity
                                    .METRIC
                                : mappingDetails.open_hole_volume_capacity
                                    .IMPERIAL
                            "
                            @change_unit_handler="
                              (val) =>
                                changeUnitHandler(
                                  'open_hole_volume_capacity',
                                  val
                                )
                            "
                            :key="refreshUnitkey"
                          />
                        </div>
                      </div>
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <div class="flex items-center justify-center">
                        <div>
                          Volume({{
                            getVolumeUnits(
                              conversionFactors?.open_hole_volume_length
                                ?.from_unit &&
                                conversionFactors?.open_hole_volume_length
                                  ?.from_unit != ""
                                ? conversionFactors.open_hole_volume_length
                                    .from_unit
                                : isWellMetrics
                                ? mappingDetails.open_hole_volume_length.METRIC
                                : mappingDetails.open_hole_volume_length
                                    .IMPERIAL,
                              conversionFactors?.open_hole_volume_capacity
                                ?.from_unit &&
                                conversionFactors?.open_hole_volume_capacity
                                  ?.from_unit != ""
                                ? conversionFactors.open_hole_volume_capacity
                                    .from_unit
                                : isWellMetrics
                                ? mappingDetails.open_hole_volume_capacity
                                    .METRIC
                                : mappingDetails.open_hole_volume_capacity
                                    .IMPERIAL
                            )?.unit || ""
                          }})
                        </div>
                      </div>
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Pump Strokes (stks)
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Time (min)
                    </th>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 uppercase font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      DP X Open Hole
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholedplength"
                        @change="
                          storeOriginalvalues('openholedplength', $event)
                        "
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="ohdpannularcapacity"
                        @change="
                          storeOriginalvalues('ohdpannularcapacity', $event)
                        "
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="dpannularvolume"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="dpannularstrokes"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholedptime"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 uppercase font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      HWDP X Open Hole
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholehwdplength"
                        @change="
                          storeOriginalvalues('openholehwdplength', $event)
                        "
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="hwdpannularcapacity"
                        @change="
                          storeOriginalvalues('hwdpannularcapacity', $event)
                        "
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="hwdpannularvolume"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="hwdpannularstrokes"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholehwdptime"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 uppercase font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      DC 2 X Open Hole
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholedc2length"
                        @change="
                          storeOriginalvalues('openholedc2length', $event)
                        "
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="ohdc2annularcapacity"
                        @change="
                          storeOriginalvalues('ohdc2annularcapacity', $event)
                        "
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="dc2annularvolume"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="ohdc2annularstrokes"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholedc2time"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 uppercase font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      DC 1 X Open Hole
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholedc1length"
                        @change="
                          storeOriginalvalues('openholedc1length', $event)
                        "
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="ohdc1annularcapacity"
                        @change="
                          storeOriginalvalues('ohdc1annularcapacity', $event)
                        "
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="dc1annularvolume"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="dc1annularstrokes"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholedc1time"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 uppercase font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Total
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="totopenholelength"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="openholevolume"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="totopenholestrokes"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="totalopenholetime"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">Total Annular Volume</h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table
                  class="border-collapse border pump w-full rounded-sm"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <tr>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Total Annular Volume ({{
                        getVolumeUnits(
                          conversionFactors?.casing_annular_volume_length
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_length
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_length
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_length.METRIC
                            : mappingDetails.casing_annular_volume_length
                                .IMPERIAL,
                          conversionFactors?.casing_annular_volume_capacity
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_capacity
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_capacity
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_capacity
                                .METRIC
                            : mappingDetails.casing_annular_volume_capacity
                                .IMPERIAL
                        )?.unit || ""
                      }})
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Pump Strokes (stks)
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Time (min)
                    </th>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="annularvolumeV"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="lagstrokes"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="lagtime"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">
                Total Well System Volume
              </h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table
                  class="border-collapse border pump w-full rounded-sm"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <tr>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Total Well System Volume ({{
                        getVolumeUnits(
                          conversionFactors?.casing_annular_volume_length
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_length
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_length
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_length.METRIC
                            : mappingDetails.casing_annular_volume_length
                                .IMPERIAL,
                          conversionFactors?.casing_annular_volume_capacity
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_capacity
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_capacity
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_capacity
                                .METRIC
                            : mappingDetails.casing_annular_volume_capacity
                                .IMPERIAL
                        )?.unit || ""
                      }})
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Pump Strokes (stks)
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Time (min)
                    </th>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="cycle_volume_v"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="cycle_strokes"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="cycle_time"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">Active Surface Volume</h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table
                  class="border-collapse border pump w-full rounded-sm"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <tr>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Active Surface Volume ({{
                        getVolumeUnits(
                          conversionFactors?.casing_annular_volume_length
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_length
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_length
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_length.METRIC
                            : mappingDetails.casing_annular_volume_length
                                .IMPERIAL,
                          conversionFactors?.casing_annular_volume_capacity
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_capacity
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_capacity
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_capacity
                                .METRIC
                            : mappingDetails.casing_annular_volume_capacity
                                .IMPERIAL
                        )?.unit || ""
                      }})
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Pump Strokes (stks)
                    </th>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="active_surface_vol"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="active_surface_vol_pumpstrokes"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">
                Total Active Fluid System
              </h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table
                  class="border-collapse border pump w-full rounded-sm"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <tr>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Total Active Fluid System ({{
                        getVolumeUnits(
                          conversionFactors?.casing_annular_volume_length
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_length
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_length
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_length.METRIC
                            : mappingDetails.casing_annular_volume_length
                                .IMPERIAL,
                          conversionFactors?.casing_annular_volume_capacity
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_capacity
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_capacity
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_capacity
                                .METRIC
                            : mappingDetails.casing_annular_volume_capacity
                                .IMPERIAL
                        )?.unit || ""
                      }})
                    </th>
                    <th
                      class="border text-xxxs px-1 font-medium"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      Pump Strokes (stks)
                    </th>
                  </tr>
                  <tr>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="tot_active_fluid_sys"
                      />
                    </td>
                    <td
                      class="border text-xxxs px-1 font-light text-sx"
                      :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }"
                    >
                      <input
                        :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                        v-model="tot_active_fluid_sys_pumpstrokes"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">Surface Line Volume</h4>
              <div class="mb-2 pl-2 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Surface Line Volume ({{
                    getVolumeUnits(
                      conversionFactors?.casing_annular_volume_length
                        ?.from_unit &&
                        conversionFactors?.casing_annular_volume_length
                          ?.from_unit != ""
                        ? conversionFactors.casing_annular_volume_length
                            .from_unit
                        : isWellMetrics
                        ? mappingDetails.casing_annular_volume_length.METRIC
                        : mappingDetails.casing_annular_volume_length.IMPERIAL,
                      conversionFactors?.casing_annular_volume_capacity
                        ?.from_unit &&
                        conversionFactors?.casing_annular_volume_capacity
                          ?.from_unit != ""
                        ? conversionFactors.casing_annular_volume_capacity
                            .from_unit
                        : isWellMetrics
                        ? mappingDetails.casing_annular_volume_capacity.METRIC
                        : mappingDetails.casing_annular_volume_capacity.IMPERIAL
                    )?.unit || ""
                  }})
                </label>

                <input
                  name=""
                  v-model="surface_line_volume"
                  @change="storeOriginalvalues('surface_line_volume', $event)"
                  class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id="leak"
                  type="text"
                  placeholder="Enter value"
                  :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                    backgroundColor: 'transparent',
                  }"
                />
              </div>
              <div class="mb-2 pl-2 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password"
                  >Surface Line Strokes (stks)
                </label>
                <input
                  :disabled="shouldDisable"
                  name=""
                  v-model="surface_line_volume_strokes"
                  class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id=""
                  type="text"
                  :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="px-2">
          <div class="flex items-center justify-between py-3">
            <div class="flex items-center">
              <button
                class="dispcalcbtn text-xxxxxs font-semibold uppercase relative"
                v-on:click="dispcalc"
              >
                Click Here For Pump Displacement Calc
              </button>
             
              <div class="dispcalcpopupcl" v-show="!dispcalcpopup">
                <div
                  class="dispop"
                  :style="{
                    height: this.widgetdisppopHeight + 'px',
                    width: this.widgetdisppopWidth + 'px',
                  }"
                >
                  <div class="p-5">
                    <h4 class="font-semibold pb-1 pl-1 text-xxs text_theme">
                      Pump Displacement Inputs
                    </h4>
                    <div
                      class="mb-2 pl-4 pt-3 flex justify-between items-center"
                      style="padding-right: 16px"
                    >
                      <label class="block text-xxxs font-medium text_theme">
                        Pump Number</label
                      >
                      <select
                        v-model="dispselected"
                        @change="dispselectPumpno"
                        class="ml-2 pmpselc text-xxxs text_theme focus:outline-none focus-visible:outline-none"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }"
                      >
                        <option value="select" disabled>Please Select</option>
                        <option
                          v-for="option in options"
                          :value="option.text"
                          :key="option.text"
                        >
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                    <div class="mb-2 px-4 flex justify-between items-center">
                      <label class="block text-xxxs font-medium text_theme">
                        Liner Diameter
                      </label>
                      <div class="flex items-center">
                        <input
                          name=""
                          v-model="diameter"
                          class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="leak"
                          type="text"
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                            backgroundColor: 'transparent',
                            height:'19px'
                          }"
                          @change="
                            storeOriginalvalues('linear_diameter', $event)
                          "
                        />
                        <div class="killsheet_widget_unit_select ml-1">
                          <UnitSelect
                            :display-id="displayId"
                            :widget-id="widgetId"
                            :mnemonic="'dmea'"
                            :pt="'pt-0'"
                            :category="'Length'"
                            :hide-label="true"
                            :is-custom-events="true"
                            :reverse="true"
                            :standaredUnit="
                              mappingDetails.linear_diameter.standard_units
                            "
                            :isMetrics="isWellMetrics"
                            :selected-val="
                              conversionFactors?.linear_diameter?.unit &&
                              conversionFactors?.linear_diameter?.unit != ''
                                ? conversionFactors.linear_diameter.unit
                                : isWellMetrics
                                ? mappingDetails.linear_diameter.METRIC
                                : mappingDetails.linear_diameter.IMPERIAL
                            "
                            @change_unit_handler="
                              (val) => changeUnitHandler('linear_diameter', val)
                            "
                            :key="refreshUnitkey"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-2 px-4 flex justify-between items-center">
                      <label class="block text-xxxs font-medium text_theme">
                        Liner/Stroke Length
                      </label>
                      <div class="flex items-center">
                        <input
                          name=""
                          v-model="strokelength"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id=""
                          type="text"
                          placeholder="Enter value"
                          :style="{
                            background:
                              darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            backgroundColor: 'transparent',
                            height:'19px'
                          }"
                          @change="
                            storeOriginalvalues('linear_stroke_langth', $event)
                          "
                        />
                        <div class="killsheet_widget_unit_select ml-1">
                          <UnitSelect
                            :display-id="displayId"
                            :widget-id="widgetId"
                            :mnemonic="'dmea'"
                            :pt="'pt-0'"
                            :category="'Length'"
                            :hide-label="true"
                            :is-custom-events="true"
                            :reverse="true"
                            :standaredUnit="
                              mappingDetails.linear_stroke_langth.standard_units
                            "
                            :isMetrics="isWellMetrics"
                            :selected-val="
                              conversionFactors?.linear_stroke_langth?.unit &&
                              conversionFactors?.linear_stroke_langth?.unit !=
                                ''
                                ? conversionFactors.linear_stroke_langth.unit
                                : isWellMetrics
                                ? mappingDetails.linear_stroke_langth.METRIC
                                : mappingDetails.linear_stroke_langth.IMPERIAL
                            "
                            @change_unit_handler="
                              (val) =>
                                changeUnitHandler('linear_stroke_langth', val)
                            "
                            :key="refreshUnitkey"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-2 px-4 flex justify-between items-center">
                      <label
                        class="block text-xxxs font-medium text_theme"
                        for="password"
                        @input="intonly"
                      >
                        Pump Efficiency (in %)
                      </label>
                      <input
                        name=""
                        v-model="efficiency"
                        class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id=""
                        type="text"
                        placeholder="Enter value"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                          height:'19px'
                        }"
                      />
                    </div>
                    <div class="mb-2 pr-5 pt-5 flex justify-end items-center">
                      <button
                        v-on:click="dispopopn"
                        class="dispcalcnclbtn text-xxs mr-5 font-semibold px-5 py-2"
                      >
                        Cancel
                      </button>
                      <button
                        v-on:click="dispsave"
                        class="dispcalcbtn text-xxs mr-5 font-semibold px-5 py-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
           
            </div>
            <div class="flex items-center" :class="{ dispdata: isdispdata }">
              <h4 class="font-semibold pb-1 text-xxs inline-block pt-1 pr-4">
                Pump No
              </h4>
              <select
                v-model="selected"
                @change="selectPumpno"
                class="pmpselc text-xxxs text-white focus:outline-none focus-visible:outline-none"
                :style="{
                  background: darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                  color: darkDark !== 'white' ? '#fff' : '#222',
                  borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  transition: 'var(--transition)',
                }"
              >
                <option value="select" disabled>Please Select</option>
                <option
                  v-for="option in options"
                  :value="option.text"
                  :key="option.text"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="pumpdiv pt-2">
            <table class="border-collapse border pump w-full">
              <tr>
                <th
                  class="border text-xxxs font-medium"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  Pump No.
                </th>
                <th
                  class="border text-xxxs font-medium"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  Displacement ({{
                    getVolumeUnits(
                      conversionFactors?.pre_recorded_volume_data_length
                        ?.from_unit &&
                        conversionFactors?.pre_recorded_volume_data_length
                          ?.from_unit != ""
                        ? conversionFactors.pre_recorded_volume_data_length
                            .from_unit
                        : isWellMetrics
                        ? mappingDetails.pre_recorded_volume_data_length.METRIC
                        : mappingDetails.pre_recorded_volume_data_length
                            .IMPERIAL,
                      conversionFactors?.pre_recorded_volume_data_capacity
                        ?.from_unit &&
                        conversionFactors?.pre_recorded_volume_data_capacity
                          ?.from_unit != ""
                        ? conversionFactors.pre_recorded_volume_data_capacity
                            .from_unit
                        : isWellMetrics
                        ? mappingDetails.pre_recorded_volume_data_capacity
                            .METRIC
                        : mappingDetails.pre_recorded_volume_data_capacity
                            .IMPERIAL
                    )?.unit || ""
                  }}/stk)
                </th>
              </tr>
              <tr>
                <td
                  class="border text-xxxs px-2"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <input
                    :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id=""
                    placeholder="Enter value"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }"
                    value="Pump No 1"
                  />
                </td>
                <td
                  class="border text-xxxs px-2"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <span
                    :style="{
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }"
                    >{{
                      pumpFilterHandler(
                        pumpoutput1,
                        getVolumeUnits(
                          conversionFactors?.casing_annular_volume_length
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_length
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_length
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_length.METRIC
                            : mappingDetails.casing_annular_volume_length
                                .IMPERIAL,
                          conversionFactors?.casing_annular_volume_capacity
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_capacity
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_capacity
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_capacity
                                .METRIC
                            : mappingDetails.casing_annular_volume_capacity
                                .IMPERIAL
                        )?.factor || ""
                      )
                    }}</span
                  >
                </td>
              </tr>
              <tr>
                <td
                  class="border text-xxxs px-2"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <input
                    :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id=""
                    placeholder="Enter value"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }"
                    value="Pump No 2"
                  />
                </td>
                <td
                  class="border text-xxxs px-2"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <span
                    :style="{
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }"
                    >{{
                      pumpFilterHandler(
                        pumpoutput2,
                        getVolumeUnits(
                          conversionFactors?.casing_annular_volume_length
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_length
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_length
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_length.METRIC
                            : mappingDetails.casing_annular_volume_length
                                .IMPERIAL,
                          conversionFactors?.casing_annular_volume_capacity
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_capacity
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_capacity
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_capacity
                                .METRIC
                            : mappingDetails.casing_annular_volume_capacity
                                .IMPERIAL
                        )?.factor || ""
                      )
                    }}</span
                  >
                </td>
              </tr>
              <tr>
                <td
                  class="border text-xxxs px-2"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <input
                    :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id=""
                    placeholder="Enter value"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }"
                    value="Pump No 3"
                  />
                </td>
                <td
                  class="border text-xxxs px-2"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <span
                    :style="{
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }"
                    >{{
                      pumpFilterHandler(
                        pumpoutput3,
                        getVolumeUnits(
                          conversionFactors?.casing_annular_volume_length
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_length
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_length
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_length.METRIC
                            : mappingDetails.casing_annular_volume_length
                                .IMPERIAL,
                          conversionFactors?.casing_annular_volume_capacity
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_capacity
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_capacity
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_capacity
                                .METRIC
                            : mappingDetails.casing_annular_volume_capacity
                                .IMPERIAL
                        )?.factor || ""
                      )
                    }}</span
                  >
                </td>
              </tr>
              <tr>
                <td
                  class="border text-xxxs px-2"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <input
                    :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id=""
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }"
                    value="Pump No 4"
                  />
                </td>
                <td
                  class="border text-xxxs px-2"
                  :style="{
                    borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  }"
                >
                  <span
                    :style="{
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }"
                    >{{
                      pumpFilterHandler(
                        pumpoutput4,
                        getVolumeUnits(
                          conversionFactors?.casing_annular_volume_length
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_length
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_length
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_length.METRIC
                            : mappingDetails.casing_annular_volume_length
                                .IMPERIAL,
                          conversionFactors?.casing_annular_volume_capacity
                            ?.from_unit &&
                            conversionFactors?.casing_annular_volume_capacity
                              ?.from_unit != ""
                            ? conversionFactors.casing_annular_volume_capacity
                                .from_unit
                            : isWellMetrics
                            ? mappingDetails.casing_annular_volume_capacity
                                .METRIC
                            : mappingDetails.casing_annular_volume_capacity
                                .IMPERIAL
                        )?.factor || ""
                      )
                    }}</span
                  >
                </td>
              </tr>
            </table>
          </div>
          <div class="py-1">
            <h4 class="font-semibold pb-0 text-xxs">Slow Pump Rate Data</h4>
            <div class="py-1 pumpdiv">
              <table
                class="border-collapse border pump w-full"
                :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }"
              >
                <tr>
                  <th></th>
                  <th
                    rowspan="2"
                    class="border text-xxxs font-medium"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    Slow Pump Rate Data (SPM)
                  </th>
                  <th
                    colspan="4"
                    class="border text-xxxs font-medium"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <div class="flex items-center justify-center">
                      <div>Dynamic Pressure Loss</div>
                      <div class="killsheet_widget_unit_select_table ml-1">
                        <UnitSelect
                          :display-id="displayId"
                          :widget-id="widgetId"
                          :mnemonic="'dmea'"
                          :pt="'pt-0'"
                          :category="'Pressure'"
                          :hide-label="true"
                          :is-custom-events="true"
                          :reverse="true"
                          :standaredUnit="
                            mappingDetails.dynamic_pressure_loss.standard_units
                          "
                          :isMetrics="isWellMetrics"
                          :selected-val="
                            conversionFactors?.dynamic_pressure_loss?.unit &&
                            conversionFactors?.dynamic_pressure_loss?.unit != ''
                              ? conversionFactors.dynamic_pressure_loss.unit
                              : isWellMetrics
                              ? mappingDetails.dynamic_pressure_loss.METRIC
                              : mappingDetails.dynamic_pressure_loss.IMPERIAL
                          "
                          @change_unit_handler="
                            (val) =>
                              changeUnitHandler('dynamic_pressure_loss', val)
                          "
                          :key="refreshUnitkey"
                        />
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td></td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    Pump No.1
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    Pump No.2
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    Pump No.3
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    Pump No.4
                  </td>
                </tr>
                <tr>
                  <td
                    class="border text-xxxs px-2 relative chec"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <label
                      class="lbl"
                      :class="{
                        slpmprate2: isslpmprate2,
                        slpmprate3: isslpmprate3,
                      }"
                      ><input
                        type="checkbox"
                        v-model="checkbox_1"
                        :checked="checkbox_1"
                        class="chekbox 1"
                        @click="slpmprate1"
                        ref="theCheckbox1" /><span class="checkmark"></span
                    ></label>
                   
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmprate25"
                      @change="storeOriginalvalues('slopmprate25', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp1rate25"
                      @change="storeOriginalvalues('slopmp1rate25', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp2rate25"
                      @change="storeOriginalvalues('slopmp2rate25', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp3rate25"
                      @change="storeOriginalvalues('slopmp3rate25', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp4rate25"
                      @change="storeOriginalvalues('slopmp4rate25', $event)"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    class="border text-xxxs px-2 relative chec"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <label
                      class="lbl"
                      :class="{
                        slpmprate1: isAddClass,
                        slpmprate3: isslpmprate3,
                      }"
                      ><input
                        type="checkbox"
                        v-model="checkbox_2"
                        :checked="checkbox_2"
                        class="chekbox 2"
                        @click="slpmprate2"
                        ref="theCheckbox2" /><span class="checkmark"></span
                    ></label>
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmprate35"
                      @change="storeOriginalvalues('slopmprate35', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp1rate35"
                      @change="storeOriginalvalues('slopmp1rate35', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp2rate35"
                      @change="storeOriginalvalues('slopmp2rate35', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp3rate35"
                      @change="storeOriginalvalues('slopmp3rate35', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp4rate35"
                      @change="storeOriginalvalues('slopmp4rate35', $event)"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    class="border text-xxxs px-2 relative chec"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <label
                      class="lbl"
                      :class="{
                        slpmprate1: isAddClass,
                        slpmprate2: isslpmprate2,
                      }"
                      ><input
                        type="checkbox"
                        v-model="checkbox_3"
                        :checked="checkbox_3"
                        class="chekbox 3"
                        @click="slpmprate3"
                        ref="theCheckbox3" /><span class="checkmark"></span
                    ></label>
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmprate45"
                      @change="storeOriginalvalues('slopmprate45', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp1rate45"
                      @change="storeOriginalvalues('slopmp1rate45', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp2rate45"
                      @change="storeOriginalvalues('slopmp2rate45', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp3rate45"
                      @change="storeOriginalvalues('slopmp3rate45', $event)"
                    />
                  </td>
                  <td
                    class="border text-xxxs px-2"
                    :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }"
                  >
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id=""
                      placeholder="Enter value"
                      :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }"
                      v-on:change="dplval"
                      v-model.number="slopmp4rate45"
                      @change="storeOriginalvalues('slopmp4rate45', $event)"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div class="pt-3 px-1">
              <h4 class="font-semibold pb-1 pl-1 text-xxs">Kick Data</h4>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Shut In Drill Pipe Pressure
                </label>
                <div class="flex items-center">
                  <input
                    v-model="vuserinp.vuserdata.sidpp"
                    class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 w-32 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    id="leak"
                    type="text"
                    placeholder="Enter value"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                      backgroundColor: 'transparent',
                    }"
                    @change="
                      storeOriginalvalues('shut_in_drill_pipe_pressure', $event)
                    "
                  />
                  <div class="killsheet_widget_unit_select ml-1">
                    <UnitSelect
                      :display-id="displayId"
                      :widget-id="widgetId"
                      :mnemonic="'dmea'"
                      :pt="'pt-0'"
                      :category="'Pressure'"
                      :hide-label="true"
                      :is-custom-events="true"
                      :reverse="true"
                      :standaredUnit="
                        mappingDetails.shut_in_drill_pipe_pressure
                          .standard_units
                      "
                      :isMetrics="isWellMetrics"
                      :selected-val="
                        conversionFactors?.shut_in_drill_pipe_pressure?.unit &&
                        conversionFactors?.shut_in_drill_pipe_pressure?.unit !=
                          ''
                          ? conversionFactors.shut_in_drill_pipe_pressure.unit
                          : isWellMetrics
                          ? mappingDetails.shut_in_drill_pipe_pressure.METRIC
                          : mappingDetails.shut_in_drill_pipe_pressure.IMPERIAL
                      "
                      @change_unit_handler="
                        (val) =>
                          changeUnitHandler('shut_in_drill_pipe_pressure', val)
                      "
                      :key="refreshUnitkey"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Shut In Casing Pressure
                </label>
                <div class="flex items-center">
                  <input
                    v-model="vuserinp.vuserdata.sicp"
                    class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-32 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    id=""
                    type="text"
                    placeholder="Enter value"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                      backgroundColor: 'transparent',
                    }"
                    @change="
                      storeOriginalvalues(
                        'shut_in_casing_pipe_pressure',
                        $event
                      )
                    "
                  />
                  <div class="killsheet_widget_unit_select ml-1">
                    <UnitSelect
                      :display-id="displayId"
                      :widget-id="widgetId"
                      :mnemonic="'dmea'"
                      :pt="'pt-0'"
                      :category="'Pressure'"
                      :hide-label="true"
                      :is-custom-events="true"
                      :reverse="true"
                      :standaredUnit="
                        mappingDetails.shut_in_casing_pipe_pressure
                          .standard_units
                      "
                      :isMetrics="isWellMetrics"
                      :selected-val="
                        conversionFactors?.shut_in_casing_pipe_pressure?.unit &&
                        conversionFactors?.shut_in_casing_pipe_pressure?.unit !=
                          ''
                          ? conversionFactors.shut_in_casing_pipe_pressure.unit
                          : isWellMetrics
                          ? mappingDetails.shut_in_casing_pipe_pressure.METRIC
                          : mappingDetails.shut_in_casing_pipe_pressure.IMPERIAL
                      "
                      @change_unit_handler="
                        (val) =>
                          changeUnitHandler('shut_in_casing_pipe_pressure', val)
                      "
                      :key="refreshUnitkey"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Pit Gain
                </label>
                <div class="flex items-center">
                  <input
                    v-model="pitgain"
                    class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-32 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                    id=""
                    type="text"
                    placeholder="Enter value"
                    :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                      backgroundColor: 'transparent',
                    }"
                  />
                  <div class="killsheet_widget_unit_select ml-1">
                    <UnitSelect
                      :display-id="displayId"
                      :widget-id="widgetId"
                      :mnemonic="'dmea'"
                      :pt="'pt-0'"
                      :category="'Volume'"
                      :hide-label="true"
                      :is-custom-events="true"
                      :reverse="true"
                      :standaredUnit="mappingDetails.pit_gain.standard_units"
                      :isMetrics="isWellMetrics"
                      :selected-val="
                        conversionFactors?.pit_gain?.unit &&
                        conversionFactors?.pit_gain?.unit != ''
                          ? conversionFactors.pit_gain.unit
                          : isWellMetrics
                          ? mappingDetails.pit_gain.METRIC
                          : mappingDetails.pit_gain.IMPERIAL
                      "
                      @change_unit_handler="
                        (val) => changeUnitHandler('pit_gain', val)
                      "
                      :key="refreshUnitkey"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Max Allowable Mud Density ({{
                    conversionFactors?.density?.from_unit &&
                    conversionFactors?.density?.from_unit != ""
                      ? conversionFactors.density.from_unit
                      : isWellMetrics
                      ? mappingDetails.density.METRIC
                      : mappingDetails.density.IMPERIAL
                  }})
                </label>
                <input
                  :disabled="shouldDisable"
                  v-model="mamd"
                  class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-32 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id=""
                  type="text"
                  :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }"
                />
              </div>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  MAASP ({{
                    conversionFactors?.lot_pressure?.from_unit &&
                    conversionFactors?.lot_pressure?.from_unit != ""
                      ? conversionFactors.lot_pressure.from_unit
                      : isWellMetrics
                      ? mappingDetails.lot_pressure.METRIC
                      : mappingDetails.lot_pressure.IMPERIAL
                  }})
                </label>
                <input
                  :disabled="shouldDisable"
                  :value="
                    !isWellMetrics &&
                    conversionFactors?.lot_pressure?.unit &&
                    conversionFactors?.lot_pressure?.unit != ''
                      ? maasp /
                        conversionFactors?.lot_pressure?.conversion_factor
                      : maasp
                  "
                  class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-32 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id=""
                  type="text"
                  :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }"
                />
              </div>
            </div>
            <div class="conclusion px-2">
              <a href="#" class="execute-btn" v-on:click="execute">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                execute
              </a>
              <a
                href="#"
                class="minimizer flex items-center text-white text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase dropdown z-40 menu_icons_bar"
                v-if="bottomMinimizer"
                @click="maximize()"
              >
                <div class="tooltip">Maximize</div>
                <i class="fa fa-plus" aria-hidden="true"></i>
                <a class="icon_minimizer" href="#">
                  <p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M0 4H4V0H0V4ZM6 16H10V12H6V16ZM0 16H4V12H0V16ZM0 10H4V6H0V10ZM6 10H10V6H6V10ZM12 0V4H16V0H12ZM6 4H10V0H6V4ZM12 10H16V6H12V10ZM12 16H16V12H12V16Z"
                        fill="white"
                      ></path>
                    </svg>
                  </p>
                </a>
              </a>
             
              <div
                class="poup text-white px-7 py-3"
                v-show="!isHidden"
                :style="{
                  height: this.widgetpopHeight + 'px',
                  width: this.widgetpopWidth + 'px',
                }"
                :class="{ minimize: isminimize }"
              >
                <div class="flex justify-end p-1">
                  <a
                    href="#"
                    class="flex items-center text-white text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase dropdown z-40 menu_icons_bar px-4"
                    @click="minimize"
                    v-if="!bottomMinimizer"
                  >
                    <div class="tooltip colorOfKillTool">Minimize</div>
                    <i
                      class="fas fa-window-minimize"
                      style="color: var(--textColor)"
                    ></i>
                  </a>
                  <button
                    v-on:click="isHidden = !isHidden"
                    class="pr-2 menu_icons_bar"
                  >
                    <div class="tooltip colorOfKillTool">Close</div>
                    <svg width="15" height="15">
                      <path
                        d="M 5,5 l 10,10 M 15,5 l -10,10"
                        stroke="red"
                        stroke-width="2"
                      />
                    </svg>
                  </button>
                </div>
                <div class="flex justify-between p-3">
                  <div class="p-3">
                    <div class="flex">
                      <div class="firtPart-in-result border">
                        <div class="flex justify-between">
                          <div
                            class="flex text-xxs justify-around w-full border-b content-center"
                          >
                            <h6 class="font-medium p-1 border-r w-11/12 pt-px">
                              Kill Fluid Density
                              {{
                                conversionFactors?.density?.from_unit &&
                                conversionFactors?.density?.from_unit != ""
                                  ? conversionFactors.density.from_unit
                                  : isWellMetrics
                                  ? mappingDetails.density.METRIC
                                  : mappingDetails.density.IMPERIAL
                              }}
                            </h6>
                            <span
                              class="text-xxs px-3 font-semibold w-1/2 text-center flex justify-between items-center"
                              >{{ kfd }}</span
                            >
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div
                            class="flex text-xxs justify-around w-full border-b content-center"
                          >
                            <h6 class="font-medium border-r p-1 w-11/12 pt-px">
                              Kill Fluid Gradient ({{
                                isWellMetrics ? "kpa/m" : "psi/ft"
                              }})
                            </h6>
                            <span
                              class="text-xxs px-3 font-semibold w-1/2 text-center flex justify-between items-center"
                              >{{
                                isWellMetrics ? (kfg * 22.621).toFixed(3) : kfg
                              }}</span
                            >
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div
                            class="flex text-xxs justify-around w-full border-b content-center"
                          >
                            <h6 class="font-medium border-r pt-px p-1 w-11/12">
                              Initial Circulating Pressure
                            </h6>
                            <span
                              class="text-xxs px-3 font-semibold w-1/2 text-center flex justify-between items-center"
                              >{{ ICP }}</span
                            >
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div
                            class="flex text-xxs justify-around border-b w-full content-center"
                          >
                            <h6 class="font-medium border-r pt-px p-1 w-11/12">
                              Final Circulating Pressure
                            </h6>
                            <span
                              class="text-xxs px-3 font-semibold w-1/2 text-center flex justify-between items-center"
                              >{{ FCP }}</span
                            >
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div
                            class="flex text-xxs justify-around w-full content-center"
                          >
                            <h6 class="font-medium border-r pt-px p-1 w-11/12">
                              K ( psi/100 strokes )
                            </h6>
                            <p
                              class="text-xxs px-3 font-semibold w-1/2 text-center flex justify-between items-center"
                            >
                              {{ Kval }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <svg height="200" width="133">
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 50 30 l 0 160 l 45 0 l 0 -133"
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="#aaa"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 60 165 l -10 25 l 45 0 l -10 -25 l -25 0"
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="#aaa"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 60 135 l 0 30 l 25 0 l 0 -30 l -25 0"
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="#aaa"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 67 40 l 0 95 l 10 0 l 0 -95 l -10 0"
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 72 30 l 0 10"
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 65 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 50 30 l 0 20 l 45 0 l 0 -20"
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 95 50 l 15 0 "
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 95 57 l 20 0 "
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 110 30 l 0 20 "
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 115 30 l 0 28 "
                            ></path>
                          </g>
                          <g>
                            <path
                              fill="none"
                              :stroke="'var(--textColor)'"
                              stroke-width="1.5px"
                              d="M 106 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "
                            ></path>
                          </g>
                          <g>
                            <text
                              x="75"
                              y="199"
                              text-anchor="middle"
                              fill="var(--textColor)"
                              font-size="8"
                            >
                              Bit Depth {{ dmea }} ({{
                                isWellMetrics
                                  ? mappingDetails.hole_data_bit_depth.METRIC
                                  : mappingDetails.hole_data_bit_depth.IMPERIAL
                              }})
                            </text>
                          </g>
                          <g>
                            <text
                              x="33"
                              y="15"
                              text-anchor="middle"
                              fill="var(--textColor)"
                              font-size="8"
                            >
                              SIDP {{ vuserinp.vuserdata.sidpp }} ({{
                                conversionFactors?.lot_pressure_horizantal
                                  ?.from_unit &&
                                conversionFactors?.lot_pressure_horizantal
                                  ?.from_unit != ""
                                  ? conversionFactors.lot_pressure_horizantal
                                      .from_unit
                                  : isWellMetrics
                                  ? mappingHorzDetails.lot_pressure_horizantal
                                      .METRIC
                                  : mappingHorzDetails.lot_pressure_horizantal
                                      .IMPERIAL
                              }})
                            </text>
                          </g>
                          <g>
                            <text
                              transform="rotate(-90)"
                              x="-130"
                              y="110"
                              text-anchor="middle"
                              fill="var(--textColor)"
                              font-size="8"
                            >
                              SICP {{ vuserinp.vuserdata.sicp }} ({{
                                conversionFactors?.lot_pressure
                                  ?.from_unit &&
                                conversionFactors?.lot_pressure
                                  ?.from_unit != ""
                                  ? conversionFactors.lot_pressure
                                      .from_unit
                                  : isWellMetrics
                                  ? mappingDetails.lot_pressure
                                      .METRIC
                                  : mappingDetails.lot_pressure
                                      .IMPERIAL
                              }})
                            </text>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div class="pb-4">
                      <h4 class="font-semibold pb-1 text-xxs">Final Volume</h4>
                      <div class="pt-0.5 pumpdiv">
                        <table
                          class="border-collapse border pump w-full rounded-sm"
                        >
                          <tr>
                            <th class="border text-xxxs px-1 font-medium">
                              Volume
                            </th>
                            <th class="border text-xxxs px-1 font-medium">
                              Cumm. Volume ({{
                                getVolumeUnits(
                                  conversionFactors
                                    ?.casing_annular_volume_length?.from_unit &&
                                    conversionFactors
                                      ?.casing_annular_volume_length
                                      ?.from_unit != ""
                                    ? conversionFactors
                                        .casing_annular_volume_length.from_unit
                                    : isWellMetrics
                                    ? mappingDetails
                                        .casing_annular_volume_length.METRIC
                                    : mappingDetails
                                        .casing_annular_volume_length.IMPERIAL,
                                  conversionFactors
                                    ?.casing_annular_volume_capacity
                                    ?.from_unit &&
                                    conversionFactors
                                      ?.casing_annular_volume_capacity
                                      ?.from_unit != ""
                                    ? conversionFactors
                                        .casing_annular_volume_capacity
                                        .from_unit
                                    : isWellMetrics
                                    ? mappingDetails
                                        .casing_annular_volume_capacity.METRIC
                                    : mappingDetails
                                        .casing_annular_volume_capacity.IMPERIAL
                                )?.unit || ""
                              }})
                            </th>
                            <th class="border text-xxxs px-1 font-medium">
                              Pump Strokes (stks)
                            </th>
                            <th class="border text-xxxs px-1 font-medium">
                              Time (mins)
                            </th>
                          </tr>
                          <tr>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              Drill String
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="drillstringvolumeV"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="totalstrokes"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="totaltime"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              Open Hole
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="openholevolume"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="totopenholestrokes"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="totalopenholetime"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              Annular Casing
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="cassed_hole_vol"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="casingdpstrokes"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="casingdptime"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              Total Annular
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="annularvolumeV"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="lagstrokes"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="lagtime"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              Total Well System
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="cycle_volume_v"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="cycle_strokes"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="cycle_time"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              Active Surface Volume
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="active_surface_vol"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="active_surface_vol_pumpstrokes"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              Total Active Fluid System
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="tot_active_fluid_sys"
                              />
                            </td>
                            <td
                              class="border text-xxxs px-1 font-light text-sx"
                            >
                              <input
                                disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id=""
                                placeholder="Enter value"
                                v-model="tot_active_fluid_sys_pumpstrokes"
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="pb-4">
                      <h4 class="font-semibold pb-1 text-xxs">Strokes</h4>
                      <div class="pt-0.5 pumpdiv">
                        <table
                          class="border-collapse border pump w-full rounded-sm scroll"
                        >
                          <thead>
                            <tr>
                              <th
                                class="conclusion_input border text-xxxs px-1 font-medium"
                                :style="{
                                  borderColor:
                                    darkDark !== 'white' ? '#fff' : '#444',
                                }"
                              >
                                Strokes (stks)
                              </th>
                              <th
                                class="conclusion_input border text-xxxs px-1 font-medium"
                                :style="{
                                  borderColor:
                                    darkDark !== 'white' ? '#fff' : '#444',
                                }"
                              >
                                Pressure
                                {{
                                  conversionFactors?.lot_pressure
                                    ?.from_unit &&
                                  conversionFactors?.lot_pressure
                                    ?.from_unit != ""
                                    ? conversionFactors.lot_pressure
                                        .from_unit
                                    : isWellMetrics
                                    ? mappingDetails.lot_pressure
                                        .METRIC
                                    : mappingDetails.lot_pressure
                                        .IMPERIAL
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(val, id, index) in Pressure"
                              :key="index"
                            >
                              <td
                                class="conclusion_input border text-xxxs px-1 uppercase font-light text-sx"
                              >
                                {{ Strokes[id] }}
                              </td>
                              <td
                                class="conclusion_input border text-xxxs px-1 font-light text-sx"
                              >
                                {{ val }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="cid mt-5">
                    <div
                      class=""
                      :id="'verticalKillsheet' + this.widgetId"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--End Vertical Kill Sheet  -->
      <Horizontal v-show="checked" :checked="checked" :conversionFactors="conversionFactors" :id="id" :displayId="displayId" :widgetId="widgetId" :widgetdisppopHeight="widgetdisppopHeight" :widgetdisppopWidth="widgetdisppopWidth" :widgetpopHeight="widgetpopHeight" :widgetpopWidth="widgetpopWidth" :well_Id="wellId" :wellbore_Id="wellboreId" :inclination_p="inclination" :incl_data_prop="incl_data" :isReplay="isReplay" @checked_changed="(val)=>checked=val"/>
      <!-- Horizontal Kill Sheet Code  -->
      
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";
import {
  select,
  scaleLinear,
  axisBottom,
  axisLeft,
  brush,
  symbol,
  symbolCircle,
  bisector,
  pointer,
  symbolDiamond,
  line,
  max,
} from "d3";

var svg = "";
let id = null;
import URL from "../../../config";
import UnitSelect from "../../Navbars/properties/UnitSelect.vue";
import mapping from "./mapping.json";
import mappingHorizantal from "./horizantalMapping.json";
import helperServices from "../../../helper-services.js";
import Horizontal from "./Horizontal.vue"
import Vertical from "./vertical.vue";


export default {
  name: "killsheet-widget",
  components: {
    UnitSelect,
    Horizontal,
    Vertical
  },
  props: {
    cardBackground: {
      type: String,
      default: "#3f3f3f",
    },
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 350,
    },
    widgetpopHeight: {
      type: [Number, String],
      default: 550,
    },
    widgetpopWidth: {
      type: [Number, String],
      default: 1080,
    },
    widgetdisppopHeight: {
      type: [Number, String],
      default: 250,
    },
    widgetdisppopWidth: {
      type: [Number, String],
      default: 350,
    },
    displayId: String,
    id: String,
    gridColor: {
      type: String,
      default: "",
    },
    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "white",
    },
    yAxisLabel: {
      type: String,
      default: "white",
    },
    xAxisLabelSize: {
      Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    conversionFactors: {
      type: Object,
      required: false,
    },
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      checkbox_1: false,
      checkbox_2: false,
      checkbox_3: false,
      whichCheckboxClicked: "",
      eobhwcapacity: 0,
      hwcapacity: 0,
      shouldDisable: true,
      wId: null,
      tempWidgetId: null,
      rows: [],
      isHidden: true,
      dispcalcpopup: true,
      incldatanull: true,
      isHidd: true,
      checked: false,
      data: [],
      completeData: [],
      wellId: null,
      wellboreId: null,
      logId: null,
      wellboreState: null,
      logType: null,
      status: -1,
      isDis: false,
      date: "",
      list: [],
      options: [
        { text: "Pump No 1" },
        { text: "Pump No 2" },
        { text: "Pump No 3" },
        { text: "Pump No 4" },
      ],
      pumno: [{ text: "1" }, { text: "2" }, { text: "3" }],
      preDatas: [
        { text: "HWDP to KOP1" },
        { text: "DC2 to KOP1" },
        { text: "DC1 to KOP1" },
      ],
      prerecDatas: [
        { text: "HWDP KOP1 to EOB" },
        { text: "DC2 to KOP1 to EOB" },
        { text: "DC1 to KOP1 to EOB" },
      ],
      vuserinp: {
        vuserdata: {
          vlotp: "",
          rho_test: "",
          rho: "",
          vgradient: "",
          vbittvd: "",
          vholetvd: "",
        },
        kickdata: {
          sdipp: "",
          sicp: "",
          pit_gain: "",
        },
      },
      dplength: "",
      dpcapacity: "",

      hwdplength: "",
      hwdpcapacity: "",

      dc1length: "",
      dc1capacity: "",

      dc2length: "",
      dc2capacity: "",

      casingdplength: "",
      casingdpannularcapacity: "",
      casinghwdplength: "",
      casinghwdpannularcapacity: "",
      casingdc1length: "",
      casingdc2length: "",
      casingdc1annularcapacity: "",
      casingdc2annularcapacity: "",

      openholedplength: "",
      ohdpannularcapacity: "",
      ohdc1annularcapacity: "",

      openholehwdplength: "",
      hwdpannularcapacity: "",
      ohdc2annularcapacity: "",
      openholedc1length: "",
      dc1annularcapacity: "",
      openholedc2length: "",
      dc2annularcapacity: "",
      annularvolume: "",
      cycle_volume: "",
      active_surface_vol: "",
      string_strokes: "",
      totalcapacity: "",

      time: "",
      selectedArray: "",
      slopmprate25: "",
      slopmp1rate25: "",
      slopmp2rate25: "",
      slopmp3rate25: "",
      slopmp4rate25: "",
      slopmprate35: "",
      slopmp1rate35: "",
      slopmp2rate35: "",
      slopmp3rate35: "",
      slopmp4rate35: "",
      slopmprate45: "",
      slopmp1rate45: "",
      slopmp2rate45: "",
      slopmp3rate45: "",
      slopmp4rate45: "",
      slctpmprate: "t",
      slctpmp1: "",
      slctpmp2: "",
      slctpmp3: "",
      slctpmp4: "",
      incl_data: "",
      conv_factor: 0.052,
      diameter: "",
      strokelength: "",
      efficiency: "",
      Kval: "",
      FCP: "",
      ICP: "",
      kfg: "",
      kfd: "",
      dpl: "",
      bottomMinimizer: false,
      idia: "",
      md: "",
      tvd: "",
      vholetvd: "",
      dmea: "",
      dbtm: "",
      pitgain: "",
      pumpoutput1: "",
      pumpoutput2: "",
      pumpoutput3: "",
      pumpoutput4: "",
      spm1: "",
      spm2: "",
      spm3: "",
      spp: "",
      time: "",
      tot_stk: "",
      tot_spm: "",
      surface_line_volume: "",

      slop_test: "",
      Pressure: "",
      Strokes: "",
      result: [],
      minX: 0,
      maxX: 45000,
      minY: 0,
      maxY: 2000,
      width: this.widgetpopWidth,
      height: this.widgetpopHeight,
      rtColumn: [],
      rtData: [],
      isAddClass: false,
      isslpmprate2: false,
      isslpmprate3: false,
      isdispdata: true,
      isminimize: false,
      selected: "select",
      dispselected: "select",
      preseccell: "select",
      preforcell: "select",
      allSelected: false,
      userIds: [],
      select: [],
      agreement: false,
      displacement: "d",
      kop1md: "",
      kop1tvd: "",
      eobmd: "",
      eobtvd: "",
      predplength: "",
      prehwlength: "",
      predc2tokoplength: "",
      predc1tokoplength: "",
      eobdc1tocapacity: "",
      eobdc2tocapacity: "",
      dc1tokopcapacity: "",
      dc2tokopcapacity: "",
      preeoblength: "",
      prehweoblength: "",
      predc2toeoblength: "",
      predc1toeoblength: "",
      prebhalength: "",
      prehwdplength: "",
      predc2length: "",
      prehwdc1length: "",
      // tot_active_fluid_system_pumpstrokes: "",
      // surfacetoeob: "",
      // surfacetobha: "",
      // surfacetodc: "",
      kval_bit: "",
      kval_eob: "",
      kval_kop1: "",
      kcp1: "",
      kcp2: "",
      inclination: "",
      capture_screen_confirm_box: "",
      isSupportScreenshotShowing: false,
      capturedImage: "",
      diameter1: "",
      strokelength1: "",
      efficiency1: "",
      diameter2: "",
      strokelength2: "",
      efficiency2: "",
      diameter3: "",
      strokelength3: "",
      efficiency3: "",
      diameter4: "",
      strokelength4: "",
      efficiency4: "",
      refreshUnitkey: 97,
      preRecordedVolumeDataVolumeFactor: 1,
      casingAnnularVolumeVolume: 1,
      openHoleVolumeVolume: 1,
      preRecordedVolumeDataVolumeFactorHorizantal: 1,
      cardBack: false,
      bit_size: "",
      time_show: "",
      originalsValues: {},
      isWellMetrics: false,
      mappingDetails: mapping,
      mappingHorzDetails: mappingHorizantal,
    };
  },
  beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.tempWidgetId = id;
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    axiosParams() {
      // const params = new URLSearchParams();
      // params.append("well_id", this.wellId);
      // // params.append('well_id', "609b3313-1db6-468c-85b9-c62a4a29df8c");
      // params.append("well_bore_id", this.wellboreId);
      // // params.append('well_bore_id', "609b3313-1db6-468c-85b9-c62a4a29df8c");
      // params.append("customer", this.customer);
      // // params.append('customer', "apollodart");
      const params = {
        well_id: this.wellId,
        wellbore_name: this.wellboreId,
        customer_name: this.customer,
      };
      return params;
    },
    // PRE RECORDED VOLVUME DATA (DRILL STRING VOLUME) CALCULATION
    dpvolume() {
      return (
        (!isNaN(this.dpcapacity) && !isNaN(this.dplength)
          ? Math.round(this.dpcapacity * this.dplength * 1e4) / 1e4
          : 0) * this.preRecordedVolumeDataVolumeFactor
      );
    },
    dpstrokes() {
      let tempDpvolume = this.dpvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length.METRIC
            : this.mappingDetails.pre_recorded_volume_data_length.IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity.METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempDpvolume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempDpvolume) && !isNaN(this.displacement)
          ? Math.round((tempDpvolume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    dptime() {
      return !isNaN(this.dpstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dpstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    hwdpvolume() {
      return (
        (!isNaN(this.hwdpcapacity) && !isNaN(this.hwdplength)
          ? Math.round(this.hwdpcapacity * this.hwdplength * 1e4) / 1e4
          : 0) * this.preRecordedVolumeDataVolumeFactor
      );
    },
    hwdpstrokes() {
      let tempHwdpvolume = this.hwdpvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length.METRIC
            : this.mappingDetails.pre_recorded_volume_data_length.IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity.METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempHwdpvolume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempHwdpvolume) && !isNaN(this.displacement)
          ? Math.round((tempHwdpvolume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    hwdptime() {
      return !isNaN(this.hwdpstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.hwdpstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dc2volume() {
      return (
        (!isNaN(this.dc2capacity) && !isNaN(this.dc2length)
          ? Math.round(this.dc2capacity * this.dc2length * 1e4) / 1e4
          : 0) * this.preRecordedVolumeDataVolumeFactor
      );
    },
    dc2strokes() {
      let tempDc2volume = this.dc2volume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length.METRIC
            : this.mappingDetails.pre_recorded_volume_data_length.IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity.METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempDc2volume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempDc2volume) && !isNaN(this.displacement)
          ? Math.round((tempDc2volume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    dc2time() {
      return !isNaN(this.dc2strokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dc2strokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dc1volume() {
      return (
        (!isNaN(this.dc1capacity) && !isNaN(this.dc1length)
          ? Math.round(this.dc1capacity * this.dc1length * 1e4) / 1e4
          : 0) * this.preRecordedVolumeDataVolumeFactor
      );
    },
    dc1strokes() {
      let tempDc1volume = this.dc1volume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length.METRIC
            : this.mappingDetails.pre_recorded_volume_data_length.IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity.METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempDc1volume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempDc1volume) && !isNaN(this.displacement)
          ? Math.round((tempDc1volume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    dc1time() {
      return !isNaN(this.dc1strokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dc1strokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    totallength() {
      return (
        Math.round(
          (Number(this.dplength) +
            Number(this.hwdplength) +
            Number(this.dc1length) +
            Number(this.dc2length)) *
            1e4
        ) / 1e4 || 0
      );
    },
    drillstringvolumeV() {
      return (
        Math.round(
          (Number(this.dc1volume) +
            Number(this.dc2volume) +
            Number(this.hwdpvolume) +
            Number(this.dpvolume)) *
            1e4
        ) / 1e4
      );
    },
    totalstrokes() {
      return Math.round(
        Number(this.dpstrokes) +
          Number(this.hwdpstrokes) +
          Number(this.dc1strokes) +
          Number(this.dc2strokes) || 0
      );
    },
    totaltime() {
      return (
        Math.round(
          (Number(this.dptime) +
            Number(this.hwdptime) +
            Number(this.dc1time) +
            Number(this.dc2time)) *
            1e4
        ) / 1e4 || 0
      );
    },
    // ANNULAR CASING VOLUME CALCULATIONS
    cassed_hole_vol() {
      return (
        (Math.round(
          Number(this.casingdpannularcapacity) *
            Number(this.casingdplength) *
            1e4
        ) / 1e4 || 0) * this.casingAnnularVolumeVolume
      );
    },
    casingdpstrokes() {
      let tempCassed_hole_vol = this.cassed_hole_vol;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.casing_annular_volume_length?.from_unit &&
            this.conversionFactors?.casing_annular_volume_length?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_length.METRIC
            : this.mappingDetails.casing_annular_volume_length.IMPERIAL,
          this.conversionFactors?.casing_annular_volume_capacity?.from_unit &&
            this.conversionFactors?.casing_annular_volume_capacity?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_capacity.METRIC
            : this.mappingDetails.casing_annular_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempCassed_hole_vol *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempCassed_hole_vol) && !isNaN(this.displacement)
          ? Math.round((tempCassed_hole_vol / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    casingdptime() {
      return !isNaN(this.casingdpstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.casingdpstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    casinghwdpannularvolume() {
      return (
        (Number(this.casinghwdplength) && Number(this.casinghwdpannularcapacity)
          ? Math.round(
              Number(this.casinghwdplength) *
                Number(this.casinghwdpannularcapacity) *
                1e4
            ) / 1e4
          : 0) * this.casingAnnularVolumeVolume
      );
    },
    casinghwdpannularstrokes() {
      let tempCasinghwdpannularvolume = this.casinghwdpannularvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.casing_annular_volume_length?.from_unit &&
            this.conversionFactors?.casing_annular_volume_length?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_length.METRIC
            : this.mappingDetails.casing_annular_volume_length.IMPERIAL,
          this.conversionFactors?.casing_annular_volume_capacity?.from_unit &&
            this.conversionFactors?.casing_annular_volume_capacity?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_capacity.METRIC
            : this.mappingDetails.casing_annular_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempCasinghwdpannularvolume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempCasinghwdpannularvolume) && !isNaN(this.displacement)
          ? Math.round(
              (tempCasinghwdpannularvolume / this.displacement) * 1e4
            ) / 1e4
          : 0
      );
    },
    casinghwdpannulartime() {
      return !isNaN(this.casinghwdpannularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.casinghwdpannularstrokes / this.slctpmprate) * 1e4) /
            1e4
        : 0;
    },
    dc2annularvoulume() {
      return (
        (Math.round(
          Number(this.casingdc2annularcapacity) *
            Number(this.casingdc2length) *
            1e4
        ) / 1e4 || 0) * this.casingAnnularVolumeVolume
      );
    },
    dc2annularstrokes() {
      let tempDc2annularvoulume = this.dc2annularvoulume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.casing_annular_volume_length?.from_unit &&
            this.conversionFactors?.casing_annular_volume_length?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_length.METRIC
            : this.mappingDetails.casing_annular_volume_length.IMPERIAL,
          this.conversionFactors?.casing_annular_volume_capacity?.from_unit &&
            this.conversionFactors?.casing_annular_volume_capacity?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_capacity.METRIC
            : this.mappingDetails.casing_annular_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempDc2annularvoulume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempDc2annularvoulume) && !isNaN(this.displacement)
          ? Math.round((tempDc2annularvoulume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    dc2annulartime() {
      return !isNaN(this.dc2annularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dc2annularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dcannularvoulume() {
      return (
        (Math.round(
          Number(this.casingdc1annularcapacity) *
            Number(this.casingdc1length) *
            1e4
        ) / 1e4 || 0) * this.casingAnnularVolumeVolume
      );
    },
    dcannularstrokes() {
      let tempDcannularvoulume = this.dcannularvoulume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.casing_annular_volume_length?.from_unit &&
            this.conversionFactors?.casing_annular_volume_length?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_length.METRIC
            : this.mappingDetails.casing_annular_volume_length.IMPERIAL,
          this.conversionFactors?.casing_annular_volume_capacity?.from_unit &&
            this.conversionFactors?.casing_annular_volume_capacity?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_capacity.METRIC
            : this.mappingDetails.casing_annular_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempDcannularvoulume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempDcannularvoulume) && !isNaN(this.displacement)
          ? Math.round((tempDcannularvoulume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    dcannulartime() {
      return !isNaN(this.dcannularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dcannularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    totalcasingdplength() {
      return (
        Math.round(
          (Number(this.casingdplength) +
            Number(this.casinghwdplength) +
            Number(this.casingdc1length) +
            Number(this.casingdc2length)) *
            1e4
        ) / 1e4 || 0
      );
    },
    totalcasingdpvolume() {
      return (
        Math.round(
          (Number(this.cassed_hole_vol) +
            Number(this.casinghwdpannularvolume) +
            Number(this.dc2annularvoulume) +
            Number(this.dcannularvoulume)) *
            1e4
        ) / 1e4 || 0
      );
    },
    totalcasingdpannularcapacity() {
      return (
        Math.round(
          (Number(this.casingdpannularcapacity) +
            Number(this.casinghwdpannularcapacity) +
            Number(this.casingdc1annularcapacity) +
            Number(this.casingdc2annularcapacity)) *
            1e4
        ) / 1e4 || 0
      );
    },
    total_casingdpstrokes() {
      return (
        Math.round(
          (Number(this.dcannularstrokes) +
            Number(this.dc2annularstrokes) +
            Number(this.casinghwdpannularstrokes) +
            Number(this.casingdpstrokes)) *
            1e4
        ) / 1e4 || 0
      );
    },
    total_casingdptime() {
      return (
        Math.round(
          (Number(this.dc2annulartime) +
            Number(this.dcannulartime) +
            Number(this.casinghwdpannulartime) +
            Number(this.casingdptime)) *
            1e4
        ) / 1e4 || 0
      );
    },
    // OPEN HOLE VOLUME CALCULATIONS
    dpannularvolume() {
      return (
        (Math.round(
          Number(this.openholedplength) * Number(this.ohdpannularcapacity) * 1e4
        ) / 1e4 || 0) * this.openHoleVolumeVolume
      );
    },
    dpannularstrokes() {
      let tempDpannularvolume = this.dpannularvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.open_hole_volume_length?.from_unit &&
            this.conversionFactors?.open_hole_volume_length?.from_unit != ""
            ? this.conversionFactors.open_hole_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.open_hole_volume_length.METRIC
            : this.mappingDetails.open_hole_volume_length.IMPERIAL,
          this.conversionFactors?.open_hole_volume_capacity?.from_unit &&
            this.conversionFactors?.open_hole_volume_capacity?.from_unit != ""
            ? this.conversionFactors.open_hole_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.open_hole_volume_capacity.METRIC
            : this.mappingDetails.open_hole_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempDpannularvolume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempDpannularvolume) && !isNaN(this.displacement)
          ? Math.round((tempDpannularvolume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    openholedptime() {
      return !isNaN(this.dpannularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dpannularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    hwdpannularvolume() {
      return (
        (Number(this.hwdpannularcapacity) && Number(this.openholehwdplength)
          ? Math.round(
              Number(this.hwdpannularcapacity) *
                Number(this.openholehwdplength) *
                1e4
            ) / 1e4
          : 0) * this.openHoleVolumeVolume
      );
    },
    hwdpannularstrokes() {
      let tempHwdpannularvolume = this.hwdpannularvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.open_hole_volume_length?.from_unit &&
            this.conversionFactors?.open_hole_volume_length?.from_unit != ""
            ? this.conversionFactors.open_hole_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.open_hole_volume_length.METRIC
            : this.mappingDetails.open_hole_volume_length.IMPERIAL,
          this.conversionFactors?.open_hole_volume_capacity?.from_unit &&
            this.conversionFactors?.open_hole_volume_capacity?.from_unit != ""
            ? this.conversionFactors.open_hole_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.open_hole_volume_capacity.METRIC
            : this.mappingDetails.open_hole_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempHwdpannularvolume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempHwdpannularvolume) && !isNaN(this.displacement)
          ? Math.round((tempHwdpannularvolume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    openholehwdptime() {
      return !isNaN(this.hwdpannularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.hwdpannularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dc2annularvolume() {
      return (
        (Math.round(
          Number(this.ohdc2annularcapacity) *
            Number(this.openholedc2length) *
            1e4
        ) / 1e4 || 0) * this.openHoleVolumeVolume
      );
    },
    ohdc2annularstrokes() {
      let tempDc2annularvolume = this.dc2annularvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.open_hole_volume_length?.from_unit &&
            this.conversionFactors?.open_hole_volume_length?.from_unit != ""
            ? this.conversionFactors.open_hole_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.open_hole_volume_length.METRIC
            : this.mappingDetails.open_hole_volume_length.IMPERIAL,
          this.conversionFactors?.open_hole_volume_capacity?.from_unit &&
            this.conversionFactors?.open_hole_volume_capacity?.from_unit != ""
            ? this.conversionFactors.open_hole_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.open_hole_volume_capacity.METRIC
            : this.mappingDetails.open_hole_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempDc2annularvolume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempDc2annularvolume) && !isNaN(this.displacement)
          ? Math.round((tempDc2annularvolume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    openholedc2time() {
      return !isNaN(this.ohdc2annularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.ohdc2annularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dc1annularvolume() {
      return (
        (Math.round(
          Number(this.ohdc1annularcapacity) *
            Number(this.openholedc1length) *
            1e4
        ) / 1e4 || 0) * this.openHoleVolumeVolume
      );
    },
    dc1annularstrokes() {
      let tempDc1annularvolume = this.dc1annularvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.open_hole_volume_length?.from_unit &&
            this.conversionFactors?.open_hole_volume_length?.from_unit != ""
            ? this.conversionFactors.open_hole_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.open_hole_volume_length.METRIC
            : this.mappingDetails.open_hole_volume_length.IMPERIAL,
          this.conversionFactors?.open_hole_volume_capacity?.from_unit &&
            this.conversionFactors?.open_hole_volume_capacity?.from_unit != ""
            ? this.conversionFactors.open_hole_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.open_hole_volume_capacity.METRIC
            : this.mappingDetails.open_hole_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempDc1annularvolume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempDc1annularvolume) && !isNaN(this.displacement)
          ? Math.round((tempDc1annularvolume / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    openholedc1time() {
      return !isNaN(this.dc1annularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dc1annularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    totopenholelength() {
      return (
        Math.round(
          (Number(this.openholedplength) +
            Number(this.openholehwdplength) +
            Number(this.openholedc1length) +
            Number(this.openholedc2length)) *
            1e4
        ) / 1e4 || 0
      );
    },
    totopenholecapacity() {
      return (
        Math.round(
          (Number(this.ohdpannularcapacity) +
            Number(this.hwdpannularcapacity) +
            Number(this.ohdc1annularcapacity) +
            Number(this.ohdc2annularcapacity)) *
            1e4
        ) / 1e4 || 0
      );
    },
    openholevolume() {
      return (
        Math.round(
          (Number(this.dpannularvolume) +
            Number(this.hwdpannularvolume) +
            Number(this.dc1annularvolume) +
            Number(this.dc2annularvolume)) *
            1e4
        ) / 1e4 || 0
      );
    },
    totopenholestrokes() {
      return (
        Math.round(
          (Number(this.dpannularstrokes) +
            Number(this.hwdpannularstrokes) +
            Number(this.dc1annularstrokes) +
            Number(this.ohdc2annularstrokes)) *
            1e4
        ) / 1e4 || 0
      );
    },
    totalopenholetime() {
      return (
        Math.round(
          (Number(this.openholedptime) +
            Number(this.openholehwdptime) +
            Number(this.openholedc1time) +
            Number(this.openholedc2time)) *
            1e4
        ) / 1e4 || 0
      );
    },
    // TOTAL ANNULAR VOLUME CALCULATION
    annularvolumeV() {
      return !isNaN(this.totalcasingdpvolume) && !isNaN(this.openholevolume)
        ? Math.round(
            (Number(this.totalcasingdpvolume) + Number(this.openholevolume)) *
              1e4
          ) / 1e4
        : "";
    },
    lagstrokes() {
      let tempAnnularvolumeV = this.annularvolumeV;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.casing_annular_volume_length?.from_unit &&
            this.conversionFactors?.casing_annular_volume_length?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_length.METRIC
            : this.mappingDetails.casing_annular_volume_length.IMPERIAL,
          this.conversionFactors?.casing_annular_volume_capacity?.from_unit &&
            this.conversionFactors?.casing_annular_volume_capacity?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_capacity.METRIC
            : this.mappingDetails.casing_annular_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempAnnularvolumeV *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempAnnularvolumeV) && !isNaN(this.displacement)
          ? Math.round((tempAnnularvolumeV / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    lagtime() {
      return !isNaN(this.lagstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.lagstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    // TOTAL WELL SYSTEM VOLUME CALCULATION
    cycle_volume_v() {
      return !isNaN(this.annularvolumeV) && !isNaN(this.drillstringvolumeV)
        ? Math.round(
            (Number(this.annularvolumeV) + Number(this.drillstringvolumeV)) *
              1e4
          ) / 1e4
        : "";
    },
    cycle_strokes() {
      let tempcycle_volume_v = this.cycle_volume_v;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.casing_annular_volume_length?.from_unit &&
            this.conversionFactors?.casing_annular_volume_length?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_length.METRIC
            : this.mappingDetails.casing_annular_volume_length.IMPERIAL,
          this.conversionFactors?.casing_annular_volume_capacity?.from_unit &&
            this.conversionFactors?.casing_annular_volume_capacity?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_capacity.METRIC
            : this.mappingDetails.casing_annular_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempcycle_volume_v *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempcycle_volume_v) && !isNaN(this.displacement)
          ? Math.round((tempcycle_volume_v / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    cycle_time() {
      return !isNaN(this.cycle_strokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.cycle_strokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    // ACTIVE SURFACE VOLUME CALCULATION
    active_surface_vol_pumpstrokes() {
      let tempActive_surface_vol = this.active_surface_vol;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.casing_annular_volume_length?.from_unit &&
            this.conversionFactors?.casing_annular_volume_length?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_length.METRIC
            : this.mappingDetails.casing_annular_volume_length.IMPERIAL,
          this.conversionFactors?.casing_annular_volume_capacity?.from_unit &&
            this.conversionFactors?.casing_annular_volume_capacity?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_capacity.METRIC
            : this.mappingDetails.casing_annular_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempActive_surface_vol *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempActive_surface_vol) && !isNaN(this.displacement)
          ? Math.round((tempActive_surface_vol / this.displacement) * 1e4) / 1e4
          : 0
      );
    },
    // TOTAL ACTIVE FLUID SYSTEM CALCULATIONS
    tot_active_fluid_sys() {
      return (
        Math.round(
          (Number(this.active_surface_vol) + Number(this.cycle_volume_v)) * 1e4
        ) / 1e4 || 0
      );
    },
    tot_active_fluid_sys_pumpstrokes() {
      let temptot_active_fluid_sys = this.tot_active_fluid_sys;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.casing_annular_volume_length?.from_unit &&
            this.conversionFactors?.casing_annular_volume_length?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_length.METRIC
            : this.mappingDetails.casing_annular_volume_length.IMPERIAL,
          this.conversionFactors?.casing_annular_volume_capacity?.from_unit &&
            this.conversionFactors?.casing_annular_volume_capacity?.from_unit !=
              ""
            ? this.conversionFactors.casing_annular_volume_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.casing_annular_volume_capacity.METRIC
            : this.mappingDetails.casing_annular_volume_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          temptot_active_fluid_sys *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(temptot_active_fluid_sys) && !isNaN(this.displacement)
          ? Math.round((temptot_active_fluid_sys / this.displacement) * 1e4) /
              1e4
          : 0
      );
    },
    // SURFACE LINE VOLUME CALCULATIONS
    surface_line_volume_strokes() {
      let tempSurface_line_volume = this.surface_line_volume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length.METRIC
            : this.mappingDetails.pre_recorded_volume_data_length.IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_capacity.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity.METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity.IMPERIAL
        );
        if (fact && fact.factor) {
          tempSurface_line_volume *= fact.factor;
        }
      }
      return Math.round(
        !isNaN(tempSurface_line_volume) && !isNaN(this.displacement)
          ? Math.round((tempSurface_line_volume / this.displacement) * 1e4) /
              1e4
          : 0
      );
    },

    // HORIZONTAL CALCULATIONS
    // PRE RECORDED VOLUME DATA
    predpvolume() {
      return Math.round(this.predplength * this.dpcapacity * 1e4) / 1e4;
    },
    predpstrokes() {
      let tempPredpvolume = this.predpvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPredpvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPredpvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predptime() {
      let result =
        Math.round((this.predpstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },

    prehwvolume() {
      console.log("pre recorded select ", this.prehwlength, this.hwcapacity);
      let result = Math.round(this.prehwlength * this.hwcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehwstrokes() {
      let tempPrehwvolume = this.prehwvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPrehwvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPrehwvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prehwtime() {
      let result =
        Math.round((this.prehwstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    //
    predc2tokopvolume() {
      console.log(
        "pre recorded select ",
        this.predc2tokoplength,
        this.dc2tokopcapacity
      );
      let result =
        Math.round(this.predc2tokoplength * this.dc2tokopcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2tokopstrokes() {
      let tempPredc2tokopvolume = this.predc2tokopvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPredc2tokopvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPredc2tokopvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc2tokoptime() {
      let result =
        Math.round((this.predc2tokopstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    //
    predc1tokopvolume() {
      console.log(
        "pre recorded select ",
        this.predc1tokoplength,
        this.dc1tokopcapacity
      );
      let result =
        Math.round(this.predc1tokoplength * this.dc1tokopcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc1tokopstrokes() {
      let tempPredc1tokopvolume = this.predc1tokopvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPredc1tokopvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPredc1tokopvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc1tokoptime() {
      let result =
        Math.round((this.predc1tokopstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    preeobvolume() {
      let result = Math.round(this.preeoblength * this.dpcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    preeobstrokes() {
      let tempPreeobvolume = this.preeobvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPreeobvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPreeobvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    preeobtime() {
      let result =
        Math.round((this.preeobstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehweobvolume() {
      let result =
        Math.round(this.prehweoblength * this.eobhwcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehweobsstrokes() {
      let tempPrehweobvolume = this.prehweobvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPrehweobvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPrehweobvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prehweobtime() {
      let result =
        Math.round((this.prehweobsstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    //
    predc2toeobvolume() {
      let result =
        Math.round(this.predc2toeoblength * this.eobdc2tocapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2toeobsstrokes() {
      let tempPredc2toeobvolume = this.predc2toeobvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPredc2toeobvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPredc2toeobvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc2toeobtime() {
      let result =
        Math.round((this.predc2toeobsstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    //
    predc1toeobvolume() {
      let result =
        Math.round(this.predc1toeoblength * this.eobdc1tocapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc1toeobsstrokes() {
      let tempPredc1toeobvolume = this.predc1toeobvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPredc1toeobvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPredc1toeobvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc1toeobtime() {
      let result =
        Math.round((this.predc1toeobsstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prebhavolume() {
      let result = Math.round(this.prebhalength * this.dpcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prebhastrokes() {
      let tempPrebhavolume = this.prebhavolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPrebhavolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPrebhavolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prebhatime() {
      let result =
        Math.round((this.prebhastrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehwdpvolume() {
      let result =
        Math.round(this.prehwdplength * this.hwdpcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehwdpstrokes() {
      let tempPrehwdpvolume = this.prehwdpvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPrehwdpvolume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPrehwdpvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prehwdptime() {
      let result =
        Math.round((this.prehwdpstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2time() {
      let result =
        Math.round((this.predc2strokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2volume() {
      let result = Math.round(this.predc2length * this.dc2capacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2strokes() {
      let tempPredc2volume = this.predc2volume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPredc2volume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPredc2volume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc1volume() {
      let result =
        Math.round(this.prehwdc1length * this.dc1capacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehwdc1strokes() {
      let tempPredc1volume = this.predc1volume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPredc1volume *= fact.factor;
        }
      }
      let result =
        Math.round((tempPredc1volume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prehwdc1time() {
      let result =
        Math.round((this.prehwdc1strokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    pretotatallength() {
      return (
        Math.round(
          (Number(this.predplength) +
            Number(this.prehwlength) +
            Number(this.predc2tokoplength) +
            Number(this.predc1tokoplength) +
            Number(this.preeoblength) +
            Number(this.prehweoblength) +
            Number(this.predc2toeoblength) +
            Number(this.predc1toeoblength) +
            Number(this.prebhalength) +
            Number(this.prehwdplength) +
            Number(this.predc2length) +
            Number(this.prehwdc1length)) *
            1e4
        ) / 1e4
      );
    },
    pretotalcapacity() {
      // return Math.round((
      //   Number(this.dpcapacity) +
      //   Number(this.hwcapacity) +
      //   Number(this.dpcapacity) +
      //   Number(this.eobhwcapacity) +
      //   Number(this.dpcapacity) +
      //   Number(this.hwdpcapacity) +
      //   Number(this.dc2volume) +
      //   Number(this.dc1capacity)
      // ) * 1e4) / 1e4;
      return "";
    },
    pretotalvolume() {
      return (
        Math.round(
          (Number(this.predc1volume) +
            Number(this.predc2volume) +
            Number(this.prehwdpvolume) +
            Number(this.prebhavolume) +
            Number(this.preeobvolume) +
            Number(this.predc2toeobvolume) +
            Number(this.predc1toeobvolume) +
            Number(this.predpvolume) +
            Number(this.prehwvolume) +
            Number(this.predc2tokopvolume) +
            Number(this.predc1tokopvolume) +
            Number(this.prehweobvolume)) *
            1e4
        ) / 1e4
      );
    },
    pretotalstrokes() {
      return (
        Math.round(
          (Number(this.predpstrokes) +
            Number(this.prehwstrokes) +
            Number(this.predc2tokopstrokes) +
            Number(this.predc1tokopstrokes) +
            Number(this.preeobstrokes) +
            Number(this.prehweobsstrokes) +
            Number(this.predc2toeobsstrokes) +
            Number(this.predc1toeobsstrokes) +
            Number(this.prebhastrokes) +
            Number(this.prehwdpstrokes) +
            Number(this.predc2strokes) +
            Number(this.prehwdc1strokes)) *
            1e4
        ) / 1e4
      );
    },
    pretotaltime() {
      return (
        Math.round(
          (Number(this.predptime) +
            Number(this.prehwtime) +
            Number(this.predc2tokoptime) +
            Number(this.predc1tokoptime) +
            Number(this.preeobtime) +
            Number(this.prehweobtime) +
            Number(this.predc2toeobtime) +
            Number(this.predc1toeobtime) +
            Number(this.prebhatime) +
            Number(this.prehwdptime) +
            Number(this.predc2time) +
            Number(this.prehwdc1time)) *
            1e4
        ) / 1e4
      );
    },
    totalwellvolume() {
      let tempPretotalvolume = this.pretotalvolume;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          tempPretotalvolume *= fact.factor;
        }
      }
      return (
        Math.round(
          (Number(tempPretotalvolume) + Number(this.annularvolumeV)) * 1e4
        ) / 1e4
      );
    },
    cyclestrokes() {
      return (
        Math.round(
          (Number(this.casingdpstrokes) +
            Number(this.totopenholestrokes) +
            Number(this.pretotalstrokes)) *
            1e4
        ) / 1e4
      );
    },
    totalwelltime() {
      let result =
        Math.round((this.cyclestrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    tot_active_fluid_system() {
      return (
        Math.round(
          (Number(this.totalwellvolume) + Number(this.active_surface_vol)) * 1e4
        ) / 1e4
      );
    },
    tot_active_fluid_system_pumpstrokes() {
      let temptot_active_fluid_system = this.tot_active_fluid_system;
      if (this.isWellMetrics) {
        let fact = this.getVolumeUnits(
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_length_horizantal
              ?.from_unit != ""
            ? this.conversionFactors.pre_recorded_volume_data_length_horizantal
                .from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_length_horizantal
                .IMPERIAL,
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.from_unit &&
            this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
              ?.from_unit != ""
            ? this.conversionFactors
                .pre_recorded_volume_data_capacity_horizantal.from_unit
            : this.isWellMetrics
            ? this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .METRIC
            : this.mappingDetails.pre_recorded_volume_data_capacity_horizantal
                .IMPERIAL
        );
        if (fact && fact.factor) {
          temptot_active_fluid_system *= fact.factor;
        }
      }
      let result =
        Math.round((temptot_active_fluid_system / this.displacement) * 1e4) /
        1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },

    surfacetoeob() {
      return (
        // Math.round(
        //   (Number(this.predpstrokes) +
        //     Number(this.prehwstrokes) +
        //     Number(this.preeobstrokes) +
        //     Number(this.prehweobsstrokes)) *
        //   1e4
        // ) / 1e4
        Math.round((Number(this.surfacetokop) + Number(this.koptoeob)) * 1e4) /
        1e4
      );
    },
    surfacetodc() {
      // Math.round(
      //   (Number(this.predpstrokes) +
      //     Number(this.prehwstrokes) +
      //     Number(this.preeobstrokes) +
      //     Number(this.prehweobsstrokes) +
      //     Number(this.prebhastrokes) +
      //     Number(this.prehwdpstrokes)) *
      //   1e4
      // ) / 1e4
      return (
        Math.round(
          (Number(this.surfacetokop) +
            Number(this.koptoeob) +
            Number(this.prebhastrokes) +
            Number(this.prehwdpstrokes)) *
            1e4
        ) / 1e4
      );
    },
    surfacetobha() {
      return (
        // Math.round(
        //   (Number(this.predpstrokes) +
        //     Number(this.prehwstrokes) +
        //     Number(this.preeobstrokes) +
        //     Number(this.prehweobsstrokes) +
        //     Number(this.prebhastrokes)) *
        //   1e4
        // ) / 1e4
        Math.round(
          (Number(this.surfacetokop) +
            Number(this.koptoeob) +
            Number(this.prebhastrokes)) *
            1e4
        ) / 1e4
      );
    },
    koptoeob() {
      return (
        Math.round(
          (Number(this.preeobstrokes) +
            Number(this.prehweobsstrokes) +
            Number(this.predc2toeobsstrokes) +
            Number(this.predc1toeobsstrokes)) *
            1e4
        ) / 1e4
      );
    },
    surfacetokop() {
      return (
        Math.round(
          (Number(this.predpstrokes) +
            Number(this.prehwstrokes) +
            Number(this.predc2tokopstrokes) +
            Number(this.predc1tokopstrokes)) *
            1e4
        ) / 1e4
      );
    },
    surfacetobit() {
      // Math.round((this.pretotalstrokes) *
      //   1e4
      // ) / 1e4;
      // return Math.round((Number(this.surfacetodc) + Number(this.predc2strokes) + Number(this.prehwdc1strokes))  * 1e4)  / 1e4
      return Math.round(Number(this.pretotalstrokes) * 1e4) / 1e4;
    },
    // lagstrokes() {
    //   return (
    //     Math.round(
    //       Number(this.casingdpstrokes) + Number(this.totopenholestrokes) * 1e4
    //     ) / 1e4
    //   );
    // },
    mamd() {
      let tempVlotp = this.vuserinp.vuserdata.vlotp;
      let tempTvd = this.tvd;
      let tempRhotest = this.vuserinp.vuserdata.rho_test;
      let tempmamdfact = "";
      if (this.checked) {
        if (
          this.conversionFactors?.lot_pressure_horizantal?.unit &&
          this.conversionFactors?.lot_pressure_horizantal?.unit != ""
        ) {
          tempVlotp *=
            this.conversionFactors?.lot_pressure_horizantal?.conversion_factor;

          console.log(
            "this.vuserinp.vuserdata.vlotp",
            this.vuserinp.vuserdata.vlotp,
            tempVlotp
          );
        }
      } else {
        if (
          this.conversionFactors?.lot_pressure?.unit &&
          this.conversionFactors?.lot_pressure?.unit != ""
        ) {
          tempVlotp *= this.conversionFactors?.lot_pressure?.conversion_factor;

          console.log(
            "this.vuserinp.vuserdata.vlotp",
            this.vuserinp.vuserdata.vlotp,
            tempVlotp
          );
        }
      }
      if (this.isWellMetrics) {
        tempTvd *= 3.28084;
      }
      if (this.checked) {
        if (
          this.conversionFactors?.mwt_at_test_horizantal?.unit &&
          this.conversionFactors?.mwt_at_test_horizantal?.unit != ""
        ) {
          tempRhotest *=
            this.conversionFactors?.mwt_at_test_horizantal?.conversion_factor;
        }
      } else {
        if (
          this.conversionFactors?.mwt_at_test?.unit &&
          this.conversionFactors?.mwt_at_test?.unit != ""
        ) {
          tempRhotest *= this.conversionFactors?.mwt_at_test?.conversion_factor;
        }
      }

      if (
        this.conversionFactors?.density?.unit &&
        this.conversionFactors?.density?.unit != ""
      ) {
        tempmamdfact = this.conversionFactors?.density?.conversion_factor;
      }
      let result =
        Math.round(
          (Number(tempVlotp) / (Number(tempTvd) * Number(this.conv_factor)) +
            Number(tempRhotest)) *
            1e4
        ) / 1e4;
      if (tempmamdfact != "") {
        result /= tempmamdfact;
      }

      return result;
    },
    maasp() {
      let tempMamd = this.mamd;
      let tempRho = this.vuserinp.vuserdata.rho;
      let tempTvd = this.tvd;
      let tempLotPressure = "";
      if (this.checked) {
        if (
          this.conversionFactors?.mwt_at_test_horizantal?.unit &&
          this.conversionFactors?.mwt_at_test_horizantal?.unit != ""
        ) {
          tempRho *=
            this.conversionFactors?.mwt_at_test_horizantal?.conversion_factor;
        }
        if (
          this.conversionFactors?.density?.unit &&
          this.conversionFactors?.density?.unit != ""
        ) {
          tempMamd *= this.conversionFactors?.density?.conversion_factor;
        }
      } else {
        if (
          this.conversionFactors?.density?.unit &&
          this.conversionFactors?.density?.unit != ""
        ) {
          tempMamd *= this.conversionFactors?.density?.conversion_factor;
          tempRho *= this.conversionFactors?.density?.conversion_factor;
        }
      }

      if (this.checked) {
        if (
          this.conversionFactors?.lot_pressure_horizantal?.unit &&
          this.conversionFactors?.lot_pressure_horizantal?.unit != ""
        ) {
          tempLotPressure =
            this.conversionFactors?.lot_pressure_horizantal?.conversion_factor;
        }
      } else {
        if (
          this.conversionFactors?.lot_pressure?.unit &&
          this.conversionFactors?.lot_pressure?.unit != ""
        ) {
          tempLotPressure =
            this.conversionFactors?.lot_pressure?.conversion_factor;
        }
      }

      if (this.isWellMetrics) {
        tempTvd *= 3.28084;
      }
      let result =
        Math.round(
          (Number(tempMamd) - Number(tempRho)) *
            this.conv_factor *
            Number(tempTvd) *
            1e4
        ) / 1e4;
      if (this.isWellMetrics && tempLotPressure != "") {
        result /= tempLotPressure;
      }
      return result;
    },
    mamg() {
      let tempMamd = this.mamd;
      if (
        this.isWellMetrics &&
        this.conversionFactors?.density?.unit &&
        this.conversionFactors?.density?.unit != ""
      ) {
        tempMamd *= this.conversionFactors?.density?.conversion_factor;
      }
      return Math.round(Number(tempMamd) * this.conv_factor * 1e4) / 1e4;
    },
  },
  sockets: {
    connect: function () {
      console.log("socket connected in trend");
    },
    disconnect() {
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
      console.log("----------------------------------------");
    },
  },

  methods: {
    storeOriginalvalues(key, e) {
      // this.originalsValues[key] = +e.target.value;
    },
    pumpFilterHandler(originalVal = "", fact = "") {
      if (originalVal != "" && fact != "") {
        return originalVal / fact;
      } else {
        return "";
      }
    },
    getVolumeUnits(fromUnt, toUnt) {
      if (fromUnt == "ft" && toUnt == "bbl/ft") {
        return { unit: "bbl", factor: 1 };
      } else if (fromUnt == "m" && toUnt == "lit/m") {
        return { unit: "lit", factor: 0.00628981 };
      } else if (fromUnt == "m" && toUnt == "m3/m") {
        return { unit: "m3", factor: 6.28981 };
      } else if (fromUnt == "m" && toUnt == "bbl/m") {
        return { unit: "bbl", factor: 1 };
      }
    },
    changeUnitHandler(key, factor) {
      const fact = {
        unit: factor.to_unit,
        from_unit: factor.from_unit,
        conversion_factor: factor.conversion_factor,
      };
      this.$store.dispatch("rect/changSwabSearchUnit", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        key: key,
        value: fact,
      });
    },
    checkbox1Clicked(whichCheckBox) {
      //   this.whichCheckboxClicked = whichCheckBox
      //   if(whichCheckBox === 1){
      //     if(this.$refs.theCheckbox1.checked){
      //       this.$refs.theCheckbox4.checked = true
      //     }else {
      //       this.$refs.theCheckbox4.checked = false
      //     }
      //   }else if(whichCheckBox === 2) {
      //     if(this.$refs.theCheckbox2.checked){
      //       this.$refs.theCheckbox5.checked = true
      //     }else {
      //       this.$refs.theCheckbox5.checked = false
      //     }
      //   }else if(whichCheckBox === 3){
      //     if(this.$refs.theCheckbox3.checked){
      //       this.$refs.theCheckbox6.checked = true
      //     }else {
      //       this.$refs.theCheckbox6.checked = false
      //     }
      //   }
    },
    dispselectPumpno() {
      if (this.dispselected == "Pump No 1") {
        this.efficiency = this.efficiency1;
        this.diameter = this.diameter1;
        this.strokelength = this.strokelength1;
      } else if (this.dispselected == "Pump No 2") {
        this.efficiency = this.efficiency2;
        this.diameter = this.diameter2;
        this.strokelength = this.strokelength2;
      } else if (this.dispselected == "Pump No 3") {
        this.efficiency = this.efficiency3;
        this.diameter = this.diameter3;
        this.strokelength = this.strokelength3;
      } else if (this.dispselected == "Pump No 4") {
        this.efficiency = this.efficiency4;
        this.diameter = this.diameter4;
        this.strokelength = this.strokelength4;
      } else {
        this.efficiency = "";
        this.diameter = "";
        this.strokelength = "";
      }
    },
    preRecordedSelectHandler() {
      if (this.preseccell == "HWDP to KOP1") {
        this.hwcapacity = Number(this.hwdpcapacity);
      } else if (this.preseccell == "DC2 to KOP1") {
        this.hwcapacity = Number(this.dc2capacity);
      } else if (this.preseccell == "DC1 to KOP1") {
        this.hwcapacity = Number(this.dc1capacity);
      } else {
        this.hwcapacity = "";
      }
    },
    preRecordedSecondSelectHandler() {
      if (this.preforcell == "HWDP KOP1 to EOB") {
        this.eobhwcapacity = Number(this.hwdpcapacity);
      } else if (this.preforcell == "DC2 to KOP1 to EOB") {
        this.eobhwcapacity = Number(this.dc2capacity);
      } else if (this.preforcell == "DC1 to KOP1 to EOB") {
        this.eobhwcapacity = Number(this.dc1capacity);
      } else {
        this.eobhwcapacity = "";
      }
    },
    toggleInclinationDataNull() {
      this.incldatanull = !this.incldatanull;
        if(this.isReplay){
          if(this.incldatanull){
            this.inclination=8;
          }else{
            this.inclination=12;
          }
      }
    },
    // Vertical Functionality
    minimize() {
      this.isminimize = true;
      this.bottomMinimizer = true;
      console.log("this.bottomMinimizer minimize", this.bottomMinimizer);
    },
    maximize() {
      this.isminimize = false;
      this.bottomMinimizer = false;
    },
    refresh() {
      this.getapi("refresh");
    },
    async printData() {
      if('ImageCapture' in window){
      const stream = await navigator.mediaDevices.getDisplayMedia({
        preferCurrentTab: true,
      });
      const videoTrack = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(videoTrack);
      const bitmap = await imageCapture.grabFrame();
      videoTrack.stop();
      const canvas = document.getElementById("canvasOfSupport");
      const ctx = canvas.getContext("2d");
      canvas.width = bitmap.width;
      canvas.height = bitmap.height;
      ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL();
      this.capturedImage = image;
      // this.download();
      this.isSupportScreenshotShowing = true;
    }
      else{
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: { preferCurrentTab: true },
        });

        const videoTrack = stream.getVideoTracks()[0];
        // Create a video element to hold the captured stream
        const video = document.createElement('video');
        video.srcObject = stream;
        video.play();

        video.addEventListener('loadeddata', async () => {
          videoTrack.stop();
          const canvas = document.getElementById("canvasOfSupport");
          const ctx = canvas.getContext("2d");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const image = canvas.toDataURL();
          this.capturedImage = image;
          this.isSupportScreenshotShowing = true;
        });
      }
    },
    download() {
      const a = document.createElement("a");
      a.setAttribute("download", "my-image.png");
      a.setAttribute("href", this.capturedImage);
      a.click();
    },
    close() {
      this.isSupportScreenshotShowing = false;
    },
    lotpressure(key, e) {
      // this.originalsValues[key] = +e.target.value;
      // if (
      //   this.vuserinp.vuserdata.rho_test != "" &&
      //   this.vuserinp.vuserdata.vlotp != ""
      // ) {
      //   this.mamd = (
      //     this.vuserinp.vuserdata.vlotp / (this.tvd * this.conv_factor) +
      //     parseInt(this.vuserinp.vuserdata.rho_test)
      //   ).toFixed(3);
      //   this.maasp = (
      //     (this.mamd - this.vuserinp.vuserdata.rho) *
      //     this.conv_factor *
      //     this.tvd
      //   ).toFixed(3);
      //   this.mamg = (this.mamd * this.conv_factor).toFixed(3);
      // }
      if (this.vuserinp.vuserdata.rho != "") {
        let tempRho = +this.vuserinp.vuserdata.rho;
        if (!this.checked) {
          if (
            this.conversionFactors?.density?.conversion_factor &&
            this.conversionFactors?.density?.conversion_factor != ""
          ) {
            tempRho *= this.conversionFactors.density.conversion_factor;
          }
        } else {
          if (
            this.conversionFactors?.mwt_at_test_horizantal?.conversion_factor &&
            this.conversionFactors?.mwt_at_test_horizantal?.conversion_factor !=
              ""
          ) {
            tempRho *=
              this.conversionFactors.mwt_at_test_horizantal.conversion_factor;
          }
        }
        this.vuserinp.vuserdata.vgradient = tempRho * this.conv_factor;
      }
    },

    // This function is for the working of the displacement calculation
    dispcalc() {
      this.dispcalcpopup = !this.dispcalcpopup;
      this.diameter = "";
      this.strokelength = "";
      this.efficiency = "";
      this.dispselected = "select";
    },
    dispopopn() {
      this.dispcalcpopup = !this.dispcalcpopup;
      this.diameter = "";
      this.strokelength = "";
      this.efficiency = "";
      this.dispselected = "select";
    },
    toggleVerticalHorizontal() {
      this.checked = !this.checked;
    },
    dispok() {
      if (this.inclination != "") {
        this.incl_data = this.inclination;
        this.incl_data = parseFloat(this.incl_data);
        this.incldatanull = true;
        if (this.incl_data < 10 && this.incl_data >= 0) {
          this.checked = false;
        } else {
          this.checked = true;
        }
      } else {
        this.$toast.error("Input Fields Should not be blank", {
          position: "top",
          duration: 2000,
        });
      }
    },
    dispsave() {
      let intypefficiency = parseInt(this.efficiency);
      if (
        this.diameter != "" &&
        this.strokelength != "" &&
        intypefficiency != "" &&
        this.dispselected != "select"
      ) {
        let tempDiameter = this.diameter;
        let tempStrokelength = this.strokelength;
        if (
          this.conversionFactors?.linear_diameter?.conversion_factor &&
          this.conversionFactors?.linear_diameter?.conversion_factor != ""
        ) {
          tempDiameter *=
            this.conversionFactors?.linear_diameter?.conversion_factor;
        }
        if (
          this.conversionFactors?.linear_stroke_langth?.conversion_factor &&
          this.conversionFactors?.linear_stroke_langth?.conversion_factor != ""
        ) {
          tempStrokelength *=
            this.conversionFactors?.linear_stroke_langth?.conversion_factor;
        }
        if (
          this.conversionFactors?.linear_diameter_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.linear_diameter_horizantal
            ?.conversion_factor != ""
        ) {
          tempDiameter *=
            this.conversionFactors?.linear_diameter_horizantal
              ?.conversion_factor;
        }
        if (
          this.conversionFactors?.linear_stroke_langth_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.linear_stroke_langth_horizantal
            ?.conversion_factor != ""
        ) {
          tempStrokelength *=
            this.conversionFactors?.linear_stroke_langth_horizantal
              ?.conversion_factor;
        }
        let dispform = (
          0.000243 *
          tempDiameter *
          tempDiameter *
          tempStrokelength *
          (intypefficiency / 100)
        ).toFixed(3);
        console.log(
          "this.diameter",
          this.diameter,
          "tempDiameter",
          tempDiameter,
          "this.strokelength",
          this.strokelength,
          "tempStrokelength",
          tempStrokelength,
          0.000243,
          tempDiameter,
          tempDiameter,
          tempStrokelength,
          intypefficiency,
          100
        );

        if (this.dispselected == "Pump No 1") {
          this.diameter1 = this.diameter;
          this.strokelength1 = this.strokelength;
          this.efficiency1 = intypefficiency;
          this.pumpoutput1 = parseFloat(dispform);
        } else if (this.dispselected == "Pump No 2") {
          this.diameter2 = this.diameter;
          this.strokelength2 = this.strokelength;
          this.efficiency2 = intypefficiency;
          this.pumpoutput2 = parseFloat(dispform);
        } else if (this.dispselected == "Pump No 3") {
          this.diameter3 = this.diameter;
          this.strokelength3 = this.strokelength;
          this.efficiency3 = intypefficiency;
          this.pumpoutput3 = parseFloat(dispform);
        } else if (this.dispselected == "Pump No 4") {
          this.diameter4 = this.diameter;
          this.strokelength4 = this.strokelength;
          this.efficiency4 = intypefficiency;
          this.pumpoutput4 = parseFloat(dispform);
        } else {
          this.pumpoutput1 = "";
          this.pumpoutput2 = "";
          this.pumpoutput3 = "";
          this.pumpoutput4 = "";
        }
        this.dispcalcpopup = !this.dispcalcpopup;
        this.diameter = "";
        this.strokelength = "";
        this.efficiency = "";
        this.dispselected = "select";
        this.isdispdata = false;
      } else {
        this.$toast.error("Input Fields Should not be blank", {
          position: "top",
          duration: 1500,
        });
      }
    },
    selectPumpno() {
      this.selectedArray = this.selected;

      // displacement of selected pump
      if (this.selected == "Pump No 1") {
        this.displacement = this.pumpoutput1;
        this.dpl = this.slctpmp1;
      } else if (this.selected == "Pump No 2") {
        this.displacement = this.pumpoutput2;
        this.dpl = this.slctpmp2;
      } else if (this.selected == "Pump No 3") {
        this.displacement = this.pumpoutput3;
        this.dpl = this.slctpmp3;
      } else if (this.selected == "Pump No 4") {
        this.displacement = this.pumpoutput4;
        this.dpl = this.slctpmp3;
      } else {
        this.displacement = "d";
        this.dpl = "";
      }

      // calculation for time

      // Strokes Calculation

      if (this.$refs.theCheckbox1.checked || this.$refs.theCheckbox4.checked) {
        this.isAddClass = false;
        this.slpmprate1();
      }
      if (this.$refs.theCheckbox2.checked || this.$refs.theCheckbox5.checked) {
        this.isslpmprate2 = false;
        this.slpmprate2();
      }
      if (this.$refs.theCheckbox3.checked || this.$refs.theCheckbox6.checked) {
        this.isslpmprate3 = false;
        this.slpmprate3();
      }

      if (this.selected == "select") {
        this.displacement = "d";
        this.dpstrokes = "";
        this.hwdpstrokes = "";
        this.dc1strokes = "";
        this.dc2strokes = "";
        this.dpannularstrokes = "";
        this.hwdpannularstrokes = "";
        this.dc1annularstrokes = "";
        this.dc2annularstrokes = "";
        this.ohdc2annularstrokes = "";
        this.lagstrokes = "";
        this.cycle_strokes = "";
        this.active_surface_vol_pumpstrokes = "";
        this.tot_active_fluid_sys_pumpstrokes = "";

        this.hwdptime = "";
        this.dc1time = "";
        this.dc2time = "";
        this.openholedptime = "";
        this.openholehwdptime = "";
        this.openholedc1time = "";
        this.openholedc2time = "";
        this.totalopenholetime = "";
        this.totaltime = "";

        (this.slctpmprate = "t"), (this.slctpmp1 = "");
        this.slctpmp2 = "";
        this.slctpmp3 = "";
        this.slctpmp4 = "";
        this.lagtime = "";
        this.cycle_time = "";
        this.surface_line_volume_stroke = "";
        this.isAddClass = false;
        this.isslpmprate2 = false;
        this.isslpmprate3 = false;
        this.preeobstrokes = "";
        this.prebhastrokes = "";

        this.predc1strokes = "";

        this.totopenholestrokes = "";

        this.lagstrokes = "";

        this.surface_line_volume_strokes = "";

        if (
          this.$refs.theCheckbox1.checked ||
          this.$refs.theCheckbox2.checked ||
          this.$refs.theCheckbox3.checked
        ) {
          this.$refs.theCheckbox1.checked = false;
          this.$refs.theCheckbox2.checked = false;
          this.$refs.theCheckbox3.checked = false;
        }
        if (
          this.$refs.theCheckbox4.checked ||
          this.$refs.theCheckbox5.checked ||
          this.$refs.theCheckbox6.checked
        ) {
          this.$refs.theCheckbox4.checked = false;
          this.$refs.theCheckbox5.checked = false;
          this.$refs.theCheckbox6.checked = false;
        }
      }
    },

    slpmprate1() {
      this.isAddClass = !this.isAddClass;
      this.slctpmprate = this.slopmprate25;
      this.slctpmp1 = this.slopmp1rate25;
      this.slctpmp2 = this.slopmp2rate25;
      this.slctpmp3 = this.slopmp3rate25;
      this.slctpmp4 = this.slopmp4rate25;
      if (this.selected == "Pump No 1") {
        this.displacement = this.pumpoutput1;
        this.dpl = this.slctpmp1;
      } else if (this.selected == "Pump No 2") {
        this.displacement = this.pumpoutput2;
        this.dpl = this.slctpmp2;
      } else if (this.selected == "Pump No 3") {
        this.displacement = this.pumpoutput3;
        this.dpl = this.slctpmp3;
      } else if (this.selected == "Pump No 4") {
        this.displacement = this.pumpoutput4;
        this.dpl = this.slctpmp3;
      } else {
        this.displacement = "d";
        this.dpl = "";
      }

      if (this.isAddClass == false) {
        this.isAddClass = false;
        this.dptime = "";
        this.hwdptime = "";
        this.dc1time = "";
        this.dc2time = "";
        this.openholedptime = "";
        this.openholehwdptime = "";
        this.openholedc1time = "";
        this.openholedc2time = "";
        this.totalopenholetime = "";
        this.totaltime = "";
        (this.slctpmprate = "t"), (this.slctpmp1 = "");
        this.slctpmp2 = "";
        this.slctpmp3 = "";
        this.slctpmp4 = "";
        this.lagtime = "";
      }
    },
    slpmprate2() {
      this.isslpmprate2 = !this.isslpmprate2;
      this.slctpmprate = this.slopmprate35;
      this.slctpmp1 = this.slopmp1rate35;
      this.slctpmp2 = this.slopmp2rate35;
      this.slctpmp3 = this.slopmp3rate35;
      this.slctpmp4 = this.slopmp4rate35;
      if (this.selected == "Pump No 1") {
        this.displacement = this.pumpoutput1;
        this.dpl = this.slctpmp1;
      } else if (this.selected == "Pump No 2") {
        this.displacement = this.pumpoutput2;
        this.dpl = this.slctpmp2;
      } else if (this.selected == "Pump No 3") {
        this.displacement = this.pumpoutput3;
        this.dpl = this.slctpmp3;
      } else if (this.selected == "Pump No 4") {
        this.displacement = this.pumpoutput4;
        this.dpl = this.slctpmp3;
      } else {
        this.displacement = "d";
        this.dpl = "";
      }

      if (this.isslpmprate2 == false) {
        this.dptime = "";
        this.hwdptime = "";
        this.dc1time = "";
        this.dc2time = "";
        this.openholedptime = "";
        this.openholehwdptime = "";
        this.openholedc1time = "";
        this.openholedc2time = "";
        this.totalopenholetime = "";
        this.totaltime = "";
        (this.slctpmprate = "t"), (this.slctpmp1 = "");
        this.slctpmp2 = "";
        this.slctpmp3 = "";
        this.slctpmp4 = "";
        this.lagtime = "";
        this.isslpmprate2 = false;
      }
    },
    slpmprate3() {
      this.isslpmprate3 = !this.isslpmprate3;
      this.slctpmprate = this.slopmprate45;
      this.slctpmp1 = this.slopmp1rate45;
      this.slctpmp2 = this.slopmp2rate45;
      this.slctpmp3 = this.slopmp3rate45;
      this.slctpmp4 = this.slopmp4rate45;
      if (this.selected == "Pump No 1") {
        this.displacement = this.pumpoutput1;
        this.dpl = this.slctpmp1;
      } else if (this.selected == "Pump No 2") {
        this.displacement = this.pumpoutput2;
        this.dpl = this.slctpmp2;
      } else if (this.selected == "Pump No 3") {
        this.displacement = this.pumpoutput3;
        this.dpl = this.slctpmp3;
      } else if (this.selected == "Pump No 4") {
        this.displacement = this.pumpoutput4;
        this.dpl = this.slctpmp3;
      } else {
        this.displacement = "d";
        this.dpl = "";
      }
      // if (this.isslpmprate3 == true) {
      // } else {
      //   this.dptime = "";
      //   this.hwdptime = "";
      //   this.dc1time = "";
      //   this.dc2time = "";
      //   this.openholedptime = "";
      //   this.openholehwdptime = "";
      //   this.openholedc1time = "";
      //   this.openholedc2time = "";
      //   this.totalopenholetime = "";
      //   this.totaltime = "";

      //   this.lagtime = "";

      //   (this.slctpmprate = "t"), (this.slctpmp1 = "");
      //   this.slctpmp2 = "";
      //   this.slctpmp3 = "";
      //   this.slctpmp4 = "";

      //   this.isslpmprate3 = false;
      // }

      if (!this.isslpmprate3) {
        this.dptime = "";
        this.hwdptime = "";
        this.dc1time = "";
        this.dc2time = "";
        this.openholedptime = "";
        this.openholehwdptime = "";
        this.openholedc1time = "";
        this.openholedc2time = "";
        this.totalopenholetime = "";
        this.totaltime = "";
        this.lagtime = "";
        (this.slctpmprate = "t"), (this.slctpmp1 = "");
        this.slctpmp2 = "";
        this.slctpmp3 = "";
        this.slctpmp4 = "";
        this.isslpmprate3 = false;
      }
    },
    dplval() {
      if (this.$refs.theCheckbox1.checked || this.$refs.theCheckbox4.checked) {
        this.isAddClass = false;
        this.slpmprate1();
      }
      if (this.$refs.theCheckbox2.checked || this.$refs.theCheckbox5.checked) {
        this.isslpmprate2 = false;
        this.slpmprate2();
      }
      if (this.$refs.theCheckbox3.checked || this.$refs.theCheckbox6.checked) {
        this.isslpmprate3 = false;
        this.slpmprate3();
      }
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    processResult(sResult) {
      console.log("processResult ", sResult);
      console.log("processResult widgetId", this.widgetId);
    },

    windowToggle(name) {
      this.classes = name;
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    // This function is for the working of the post api Onclick of the execute button Horizontal
    executeHorizontal() {
      if (
        this.vuserinp.vuserdata.sidpp != "" &&
        this.vuserinp.vuserdata.sicp != "" &&
        this.pitgain != "" &&
        this.idia != "" &&
        this.md != "" &&
        this.bit_size != "" &&
        this.tvd != "" &&
        this.surface_line_volume != "" &&
        this.vuserinp.vuserdata.rho_test != "" &&
        this.vuserinp.vuserdata.vlotp != "" &&
        this.displacement != "" &&
        this.vuserinp.vuserdata.rho != "" &&
        this.selected != "" &&
        this.dpl != "" &&
        this.slctpmprate != "" &&
        this.vuserinp.vuserdata.vholetvd != "" &&
        this.kop1md != "" &&
        this.kop1tvd != "" &&
        this.eobmd != "" &&
        this.eobtvd != "" &&
        this.surfacetokop != ""
      ) {
        // if (this.pretotatallength != "" && this.dbtm != "") {
        //   if (Math.round(this.pretotatallength) != Math.round(this.dbtm)) {
        //     this.$toast.error(
        //       "Bit Depth and Drill String Input Length, both are not equal",
        //       {
        //         position: "top",
        //         duration: 5000,
        //       }
        //     );
        //     return false;
        //   }
        // }
        if (
          +Number(this.dbtm).toFixed(4) !=
          +(
            Number(this.totalcasingdplength) + Number(this.totopenholelength)
          ).toFixed(4)
        ) {
          this.$toast.error(
            "CASING ANNULAR TOTAL LENGTH + OPEN HOLE TOTAL LENGTH MUST BE EQUAL TO THE BIT DEPTH",
            {
              position: "top",
              duration: 1500,
            }
          );
          return;
        }
        if (
          +Number(this.dbtm).toFixed(4) !=
          +Number(this.pretotatallength).toFixed(4)
        ) {
          this.$toast.error(
            "TOTAL LENGTH IN PRE RECORDED VOLUME DATA MUST BE EQUAL TO THE BIT DEPTH",
            {
              position: "top",
              duration: 1500,
            }
          );
          return;
        }
        let payload = {
          kick_data: {
            sidpp: Number(this.vuserinp.vuserdata.sidpp),
            sicp: Number(this.vuserinp.vuserdata.sicp),
            pit_gain: Number(this.pitgain),
          },
          geometrydata: {
            idia: Number(this.idia),
            md: Number(this.md),
            odia: Number(this.bit_size),
            section_type: this.section_type,
            tvd: Number(this.tvd),
          },
          surface_data: {
            surface_line_volume: Number(this.surface_line_volume),
            active_surface_vol: Number(this.active_surface_vol),
            formation_strength_test: Number(this.vuserinp.vuserdata.vlotp),
          },
          time_data: {
            dbtm: Number(this.dbtm),
            kop1_md: Number(this.kop1md),
            kop1_tvd: Number(this.kop1tvd),
            eob_md: Number(this.eobmd),
            eob_tvd: Number(this.eobtvd),
            surface_to_kop1_strokes: Number(this.surfacetokop),
            kop1_to_eob_strokes: Number(this.koptoeob),
            surface_to_bit_strokes: Number(this.surfacetobit),
            active_surface_vol_pumpstrokes: Number(
              this.active_surface_vol_pumpstrokes
            ),
            annular_casing_vol: Number(this.annular_casing_vol || 0),
            annularvolume: Number(this.annularvolumeV),
            bit_size: Number(this.bit_size),
            casingdc1length: Number(this.casingdc1length),
            casingdpannularcapacity: Number(this.casingdpannularcapacity || 0),
            casingdplength: Number(this.casingdplength),
            casinghwdpannularcapacity: Number(this.casinghwdpannularcapacity),
            casinghwdplength: Number(this.casinghwdplength),
            cassed_hole_vol: Number(this.cassed_hole_vol),
            cycle_strokes: Number(this.cyclestrokes),
            cycle_time: Number(this.totalwelltime),
            cycle_volume: Number(this.totalwellvolume),
            dc1annularcapacity: Number(this.dc1annularcapacity),
            dc1annularstrokes: Number(this.dc1annularstrokes),
            dc1annularvolume: Number(this.dc1annularvolume),
            dc1capacity: Number(this.dc1capacity),
            dc1length: Number(this.dc1length),
            dc1strokes: Number(this.dc1strokes),
            dc1volume: Number(this.dc1volume),
            dc2annularcapacity: Number(this.dc2annularcapacity),
            dc2annularstrokes: Number(this.dc2annularstrokes),
            dc2annularvolume: Number(this.dc2annularvolume),
            dc2capacity: Number(this.dc2capacity),
            dc2length: Number(this.dc2length),
            dc2strokes: Number(this.dc2strokes),
            dc2volume: this.dc2volume,
            dmea: Number(this.dmea),
            dpannularstrokes: Number(this.dpannularstrokes),
            dpannularvolume: Number(this.dpannularvolume),
            dpcapacity: Number(this.dpcapacity),
            dplength: Number(this.predplength),
            dpstrokes: Number(this.predpstrokes),
            dpvolume: Number(this.predpvolume),
            drillstringvolume: Number(this.pretotalvolume),
            hwdpannularcapacity: Number(this.hwdpannularcapacity),
            hwdpannularstrokes: Number(this.hwdpannularstrokes),
            hwdpannularvolume: this.hwdpannularvolume,
            hwdpcapacity: Number(this.hwdpcapacity),
            hwdplength: Number(this.prehwdplength),
            hwdpstrokes: Number(this.prehwdpstrokes),
            hwdpvolume: Number(this.prehwdpvolume),
            incl_data:
              this.incl_data != null || 0 ? this.incl_data : this.inclination,
            lagstrokes: this.lagstrokes,
            lagtime: Number(this.lagtime),
            maasp: Number(this.maasp),
            mamd: Number(this.mamd),
            ohdc1annularcapacity: Number(this.dc1annularcapacity),
            ohdc2annularcapacity: Number(this.dc2annularcapacity),
            ohdpannularcapacity: Number(this.ohdpannularcapacity),
            openholedc1length: Number(this.openholedc1length),
            openholedc2length: Number(this.openholedc2length),
            openholedplength: Number(this.openholedplength),
            openholehwdplength: Number(this.openholehwdplength),
            openholevolume: Number(this.openholevolume),
            pcas: Number(this.vuserinp.vuserdata.sicp),
            pitgain: Number(this.pitgain),
            pumpoutput: this.displacement,
            rho: Number(this.vuserinp.vuserdata.rho),
            spm1: Number(this.spm1),
            spm2: Number(this.spm2),
            spm3: Number(this.spm3),
            spp: Number(this.spm1),
            string_strokes: Number(this.pretotalstrokes),
            time: this.time,
            tot_active_fluid_sys: this.tot_active_fluid_system,
            tot_active_fluid_sys_pumpstrokes: Number(
              this.tot_active_fluid_system_pumpstrokes
            ),
            total_vol: 733.641,
            rho_test: Number(this.vuserinp.vuserdata.rho_test),
            tvd: Number(this.vuserinp.vuserdata.vholetvd),
            surface_to_eob: Number(this.surfacetoeob),
          },
          pump_data: {
            pump_select: this.selected,
            displacement: this.displacement,
            dpl: Number(this.dpl),
            dpr: Number(this.slctpmprate),
          },
        };
        if (
          this.conversionFactors?.lot_pressure_horizantal?.conversion_factor &&
          this.conversionFactors?.lot_pressure_horizantal?.conversion_factor !=
            ""
        ) {
          payload.surface_data.formation_strength_test *=
            this.conversionFactors.lot_pressure_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.mwt_at_test_horizantal?.conversion_factor &&
          this.conversionFactors?.mwt_at_test_horizantal?.conversion_factor !=
            ""
        ) {
          payload.time_data.rho_test *=
            this.conversionFactors.mwt_at_test_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.current_mw_horizantal?.conversion_factor &&
          this.conversionFactors?.current_mw_horizantal?.conversion_factor != ""
        ) {
          payload.time_data.rho *=
            this.conversionFactors.current_mw_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.kop1_md?.conversion_factor &&
          this.conversionFactors?.kop1_md?.conversion_factor != ""
        ) {
          payload.time_data.kop1_md *=
            this.conversionFactors.kop1_md.conversion_factor;
        }
        if (
          this.conversionFactors?.kop1_tvd?.conversion_factor &&
          this.conversionFactors?.kop1_tvd?.conversion_factor != ""
        ) {
          payload.time_data.kop1_tvd *=
            this.conversionFactors.kop1_tvd.conversion_factor;
        }
        if (
          this.conversionFactors?.eob_md?.conversion_factor &&
          this.conversionFactors?.eob_md?.conversion_factor != ""
        ) {
          payload.time_data.eob_md *=
            this.conversionFactors.eob_md.conversion_factor;
        }
        if (
          this.conversionFactors?.eob_tvd?.conversion_factor &&
          this.conversionFactors?.eob_tvd?.conversion_factor != ""
        ) {
          payload.time_data.eob_tvd *=
            this.conversionFactors.eob_tvd.conversion_factor;
        }
        if (
          this.conversionFactors?.sidp_horizantal?.conversion_factor &&
          this.conversionFactors?.pit_gain_horizantal?.conversion_factor != ""
        ) {
          payload.kick_data.sidpp *=
            this.conversionFactors.sidp_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.pit_gain_horizantal?.conversion_factor &&
          this.conversionFactors?.sicp_horizantal?.conversion_factor != ""
        ) {
          payload.kick_data.pit_gain *=
            this.conversionFactors.pit_gain_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.pre_recorded_volume_data_length_horizantal
            ?.conversion_factor != ""
        ) {
          payload.time_data.dplength *=
            this.conversionFactors.pre_recorded_volume_data_length_horizantal.conversion_factor;
          payload.time_data.hwdplength *=
            this.conversionFactors.pre_recorded_volume_data_length_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.pre_recorded_volume_data_capacity_horizantal
            ?.conversion_factor != ""
        ) {
          payload.time_data.dpcapacity *=
            this.conversionFactors.pre_recorded_volume_data_capacity_horizantal.conversion_factor;
          payload.time_data.hwdplength *=
            this.conversionFactors.pre_recorded_volume_data_capacity_horizantal.conversion_factor;
          payload.time_data.hwdpcapacity *=
            this.conversionFactors.pre_recorded_volume_data_capacity_horizantal.conversion_factor;
          payload.time_data.dc2capacity *=
            this.conversionFactors.pre_recorded_volume_data_capacity_horizantal.conversion_factor;
          payload.time_data.dc1capacity *=
            this.conversionFactors.pre_recorded_volume_data_capacity_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.casing_annular_volume_length_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.casing_annular_volume_length_horizantal
            ?.conversion_factor != ""
        ) {
          payload.time_data.casingdplength *=
            this.conversionFactors.casing_annular_volume_length_horizantal.conversion_factor;
          payload.time_data.casinghwdplength *=
            this.conversionFactors.casing_annular_volume_length_horizantal.conversion_factor;
          payload.time_data.casingdc1length *=
            this.conversionFactors.casing_annular_volume_length_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.casing_annular_volume_capacity_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.casing_annular_volume_capacity_horizantal
            ?.conversion_factor != ""
        ) {
          payload.time_data.casingdpannularcapacity *=
            this.conversionFactors.casing_annular_volume_capacity_horizantal.conversion_factor;
          payload.time_data.casinghwdpannularcapacity *=
            this.conversionFactors.casing_annular_volume_capacity_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.open_holevolume_length_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.open_holevolume_length_horizantal
            ?.conversion_factor != ""
        ) {
          payload.time_data.openholedplength *=
            this.conversionFactors.open_holevolume_length_horizantal.conversion_factor;
          payload.time_data.openholehwdplength *=
            this.conversionFactors.open_holevolume_length_horizantal.conversion_factor;
          payload.time_data.openholedc2length *=
            this.conversionFactors.open_holevolume_length_horizantal.conversion_factor;
          payload.time_data.openholedc1length *=
            this.conversionFactors.open_holevolume_length_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.open_hole_volume_capacity_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.open_hole_volume_capacity_horizantal
            ?.conversion_factor != ""
        ) {
          payload.time_data.ohdpannularcapacity *=
            this.conversionFactors.open_hole_volume_capacity_horizantal.conversion_factor;
          payload.time_data.hwdpannularcapacity *=
            this.conversionFactors.open_hole_volume_capacity_horizantal.conversion_factor;
          payload.time_data.ohdc2annularcapacity *=
            this.conversionFactors.open_hole_volume_capacity_horizantal.conversion_factor;
          payload.time_data.ohdc1annularcapacity *=
            this.conversionFactors.open_hole_volume_capacity_horizantal.conversion_factor;
        }
        if (
          this.conversionFactors?.surface_line_volume_horizantal
            ?.conversion_factor &&
          this.conversionFactors?.surface_line_volume_horizantal
            ?.conversion_factor != ""
        ) {
          payload.surface_data.surface_line_volume *=
            this.conversionFactors.surface_line_volume_horizantal.conversion_factor;
        }
        if (this.isWellMetrics) {
          payload.time_data.tvd *= 3.28084;
        }
        if (
          this.isWellMetrics &&
          this.conversionFactors?.lot_pressure?.conversion_factor &&
          this.conversionFactors?.lot_pressure?.conversion_factor != ""
        ) {
          payload.pump_data.dpl *=
            this.conversionFactors.lot_pressure.conversion_factor;
        }
        let results = axios
          .post(URL.HOST_URL.SERVER_URL + "public/killsheet", payload)
          .then(
            (response) => {
              this.isHidd = !this.isHidd;
              console.log("response in kill sheeet:::::", response.data);
              this.result = response.data.result;
              this.Pressure = response.data.killsheetdata[0].pressure;
              this.Strokes = response.data.killsheetdata[0].strokes;
              this.FCP = response.data.killsheetdata[0].FCP;
              console.log("fcPPPP::::::::::", this.FCP);
              this.FCP = parseFloat(this.FCP.toFixed(3));
              this.ICP = response.data.killsheetdata[0].ICP;
              this.ICP = parseFloat(this.ICP.toFixed(3));
              this.kval_bit = response.data.killsheetdata[0].kval_bit;
              this.kval_bit = parseFloat(this.kval_bit.toFixed(3));
              this.kval_eob = response.data.killsheetdata[0].kval_eob;
              this.kval_eob = parseFloat(this.kval_eob.toFixed(3));
              this.kval_kop1 = response.data.killsheetdata[0].kval_kop1;
              this.kval_kop1 = parseFloat(this.kval_kop1.toFixed(3));
              this.kcp1 = response.data.killsheetdata[0].kcp1;
              this.kcp1 = parseFloat(this.kcp1.toFixed(3));
              this.kcp2 = response.data.killsheetdata[0].kcp2;
              this.kcp2 = parseFloat(this.kcp2.toFixed(3));
              this.kfg = response.data.killsheetdata[0].kfg;
              this.kfg = parseFloat(this.kfg.toFixed(3));
              this.kfd = response.data.killsheetdata[0].kfd;
              this.kfd = parseFloat(this.kfd.toFixed(3));
              this.StrokeLength = Object.keys(this.Pressure).length;
              if (
                this.conversionFactors?.lot_pressure_horizantal
                  ?.conversion_factor &&
                this.conversionFactors?.lot_pressure_horizantal
                  ?.conversion_factor != ""
              ) {
                payload.surface_data.formation_strength_test /=
                  this.conversionFactors.lot_pressure_horizantal.conversion_factor;
                const pressureUntConversion = this.Pressure.map((p) =>
                  (
                    p / this.conversionFactors.lot_pressure.conversion_factor
                  ).toFixed(3)
                );
                this.Pressure = pressureUntConversion;
                this.FCP /=
                  this.conversionFactors.lot_pressure.conversion_factor;
                this.FCP = this.FCP.toFixed(3);
                this.ICP /=
                  this.conversionFactors.lot_pressure.conversion_factor;
                this.ICP = this.ICP.toFixed(3);
                this.kcp1 /=
                  this.conversionFactors.lot_pressure.conversion_factor;
                this.kcp1 = this.kcp1.toFixed(3);
                this.kcp2 /=
                  this.conversionFactors.lot_pressure.conversion_factor;
                this.kcp2 = this.kcp2.toFixed(3);
                this.kval_bit /=
                  this.conversionFactors.lot_pressure.conversion_factor;
                this.kval_eob /=
                  this.conversionFactors.lot_pressure.conversion_factor;
                this.kval_bit = this.kval_bit.toFixed(3);
                this.kval_kop1 /=
                  this.conversionFactors.lot_pressure.conversion_factor;
                this.kval_kop1 = this.kval_kop1.toFixed(3);
              }
              if (
                this.conversionFactors?.mwt_at_test_horizantal
                  ?.conversion_factor &&
                this.conversionFactors?.mwt_at_test_horizantal
                  ?.conversion_factor != ""
              ) {
                this.kfd /=
                  this.conversionFactors.mwt_at_test_horizantal.conversion_factor;
                this.kfd = this.kfd.toFixed(3);
              }
              this.buildHoriSVG();
            },
            (error) => {
              this.$toast.error("Something Wrong", {
                position: "top",
                duration: 1500,
              });
            }
          );
      } else {
        this.$toast.error("Fields Should Not be blank", {
          position: "top",
          duration: 1500,
        });
      }
    },
    // This function is for the working of the post api Onclick of the execute button Vertical
    execute() {
      if (
        this.vuserinp.vuserdata.sidpp != "" &&
        this.vuserinp.vuserdata.sicp != "" &&
        this.pitgain != "" &&
        this.idia != "" &&
        this.md != "" &&
        this.bit_size != "" &&
        this.tvd != "" &&
        this.surface_line_volume != "" &&
        this.vuserinp.vuserdata.rho_test != "" &&
        this.vuserinp.vuserdata.vlotp != "" &&
        this.displacement != "" &&
        this.vuserinp.vuserdata.rho != "" &&
        this.selected != "" &&
        this.dpl != "" &&
        this.slctpmprate != ""
      ) {
        // if (this.pretotatallength != "" && this.dbtm != "") {
        //   if (Math.round(this.pretotatallength) != Math.round(this.dbtm)) {
        //     this.$toast.error(
        //       "Bit Depth and Drill String Input Length, both are not equal",
        //       {
        //         position: "top",
        //         duration: 5000,
        //       }
        //     );
        //     return false;
        //   }
        // }
        if (
          +Number(this.dbtm).toFixed(4) !=
          +(
            Number(this.totopenholelength) + Number(this.totalcasingdplength)
          ).toFixed(4)
        ) {
          this.$toast.error(
            "CASING ANNULAR TOTAL LENGTH + OPEN HOLE TOTAL LENGTH MUST BE EQUAL TO THE BIT DEPTH",
            {
              position: "top",
              duration: 1500,
            }
          );
          return;
        }
        if (
          +Number(this.dbtm).toFixed(4) != +Number(this.totallength).toFixed(4)
        ) {
          this.$toast.error(
            "TOTAL LENGTH IN PRE RECORDED VOLUME DATA (Drill string volume) MUST BE EQUAL TO THE BIT DEPTH",
            {
              position: "top",
              duration: 1500,
            }
          );
          return;
        }
        let payload = {
          inc_data: Number(this.incl_data),
          kick_data: {
            sidpp: parseFloat(this.vuserinp.vuserdata.sidpp),
            sicp: parseInt(this.vuserinp.vuserdata.sicp),
            pit_gain: parseFloat(this.pitgain),
          },
          geometrydata: {
            idia: parseFloat(this.idia),
            md: parseFloat(this.md),
            odia: parseFloat(this.bit_size),
            section_type: this.section_type,
            tvd: parseFloat(this.tvd),
          },
          surface_data: {
            surface_line_volume: parseFloat(this.surface_line_volume),
            active_surface_vol: Number(this.active_surface_vol),
            formation_strength_test: parseFloat(this.vuserinp.vuserdata.vlotp),
          },
          time_data: {
            rho_test: parseFloat(this.vuserinp.vuserdata.rho_test),
            tvd: parseFloat(this.vuserinp.vuserdata.vholetvd),
            active_surface_vol: parseFloat(this.active_surface_vol),
            active_surface_vol_pumpstrokes: parseFloat(
              this.active_surface_vol_pumpstrokes
            ),
            annular_casing_vol: this.annular_casing_vol,
            annularvolume: this.annularvolumeV,
            bit_size: Number(this.bit_size),
            casingdc1length: Number(this.casingdc1length),
            casingdpannularcapacity: Number(this.casingdpannularcapacity),
            casingdplength: Number(this.casingdplength),
            casinghwdpannularcapacity: Number(this.casinghwdpannularcapacity),
            casinghwdplength: Number(this.casinghwdplength),
            cassed_hole_vol: Number(this.cassed_hole_vol),
            cycle_strokes: parseFloat(this.cycle_strokes),
            cycle_time: parseFloat(this.cycle_time),
            cycle_volume: Number(this.cycle_volume_v),
            dbtm: Number(this.dbtm),
            ohdc1annularcapacity: Number(this.dc1annularcapacity),
            dc1annularstrokes: parseFloat(this.dc1annularstrokes),
            dc1annularvolume: Number(this.dc1annularvolume),
            dc1capacity: Number(this.dc1capacity),
            dc1length: Number(this.dc1length),
            dc1strokes: parseFloat(this.dc1strokes),
            dc1volume: Number(this.dc1volume),
            ohdc2annularcapacity: Number(this.dc2annularcapacity),
            dc2annularstrokes: parseFloat(this.dc2annularstrokes),
            dc2annularvolume: Number(this.dc2annularvolume),
            dc2capacity: Number(this.dc2capacity),
            dc2length: Number(this.dc2length),
            dc2strokes: parseFloat(this.dc2strokes),
            dc2volume: Number(this.dc2volume),
            dmea: parseFloat(this.dmea),
            dpannularstrokes: parseFloat(this.dpannularstrokes),
            dpannularvolume: Number(this.dpannularvolume),
            dpcapacity: Number(this.dpcapacity),
            dplength: Number(this.dplength),
            dpstrokes: parseFloat(this.dpstrokes),
            dpvolume: Number(this.dpvolume),
            drillstringvolume: Number(this.drillstringvolumeV),
            hwdpannularcapacity: Number(this.hwdpannularcapacity),
            hwdpannularstrokes: parseFloat(this.hwdpannularstrokes),
            hwdpannularvolume: Number(this.hwdpannularvolume),
            hwdpcapacity: Number(this.hwdpcapacity),
            hwdplength: Number(this.hwdplength),
            hwdpstrokes: parseFloat(this.hwdpstrokes),
            hwdpvolume: Number(this.hwdpvolume),
            incl_data: Number(this.incl_data),
            lagstrokes: parseFloat(this.lagstrokes),
            lagtime: parseFloat(this.lagtime),
            maasp: Number(this.maasp),
            mamd: Number(this.mamd),
            ohdpannularcapacity: Number(this.ohdpannularcapacity),
            openholedc1length: Number(this.openholedc1length),
            openholedc2length: Number(this.openholedc2length),
            openholedplength: Number(this.openholedplength),
            openholehwdplength: Number(this.openholehwdplength),
            openholevolume: Number(this.openholevolume),
            pcas: parseInt(this.vuserinp.vuserdata.sicp),
            pitgain: parseFloat(this.pitgain),
            pumpoutput: this.displacement,
            rho: parseFloat(this.vuserinp.vuserdata.rho),
            spm1: Number(this.spm1),
            spm2: Number(this.spm2),
            spm3: Number(this.spm3),
            spp: Number(this.spm1),
            string_strokes: parseFloat(this.totalstrokes),
            time: Number(this.time),
            tot_active_fluid_sys: Number(this.tot_active_fluid_sys),
            tot_active_fluid_sys_pumpstrokes: parseFloat(
              this.tot_active_fluid_sys_pumpstrokes
            ),
          },
          pump_data: {
            pump_select: this.selected,
            displacement: this.displacement,
            dpl: parseFloat(this.dpl),
            dpr: parseFloat(this.slctpmprate),
          },
        };
        if (
          this.conversionFactors?.lot_pressure?.conversion_factor &&
          this.conversionFactors?.lot_pressure?.conversion_factor != ""
        ) {
          payload.surface_data.formation_strength_test *=
            this.conversionFactors.lot_pressure.conversion_factor;
        }
        if (
          this.conversionFactors?.mwt_at_test?.conversion_factor &&
          this.conversionFactors?.mwt_at_test?.conversion_factor != ""
        ) {
          payload.time_data.rho_test *=
            this.conversionFactors.mwt_at_test.conversion_factor;
        }
        if (
          this.conversionFactors?.density?.conversion_factor &&
          this.conversionFactors?.density?.conversion_factor != ""
        ) {
          payload.time_data.rho *=
            this.conversionFactors.density.conversion_factor;
        }
        if (
          this.conversionFactors?.pre_recorded_volume_data_length
            ?.conversion_factor &&
          this.conversionFactors?.pre_recorded_volume_data_length
            ?.conversion_factor != ""
        ) {
          payload.time_data.dplength *=
            this.conversionFactors.pre_recorded_volume_data_length.conversion_factor;
          payload.time_data.hwdplength *=
            this.conversionFactors.pre_recorded_volume_data_length.conversion_factor;
          payload.time_data.dc2length *=
            this.conversionFactors.pre_recorded_volume_data_length.conversion_factor;
          payload.time_data.dc1length *=
            this.conversionFactors.pre_recorded_volume_data_length.conversion_factor;
        }
        if (
          this.conversionFactors?.pre_recorded_volume_data_capacity
            ?.conversion_factor &&
          this.conversionFactors?.pre_recorded_volume_data_capacity
            ?.conversion_factor != ""
        ) {
          payload.time_data.dpcapacity *=
            this.conversionFactors.pre_recorded_volume_data_capacity.conversion_factor;
          payload.time_data.hwdpcapacity *=
            this.conversionFactors.pre_recorded_volume_data_capacity.conversion_factor;
          payload.time_data.dc2capacity *=
            this.conversionFactors.pre_recorded_volume_data_capacity.conversion_factor;
          payload.time_data.dc1capacity *=
            this.conversionFactors.pre_recorded_volume_data_capacity.conversion_factor;
        }
        if (
          this.conversionFactors?.pre_recorded_volume_data_volume
            ?.conversion_factor &&
          this.conversionFactors?.pre_recorded_volume_data_volume
            ?.conversion_factor != ""
        ) {
          payload.time_data.dpvolume *=
            this.conversionFactors.pre_recorded_volume_data_volume.conversion_factor;
          payload.time_data.hwdpvolume *=
            this.conversionFactors.pre_recorded_volume_data_volume.conversion_factor;
          payload.time_data.dc2volume *=
            this.conversionFactors.pre_recorded_volume_data_volume.conversion_factor;
          payload.time_data.dc1volume *=
            this.conversionFactors.pre_recorded_volume_data_volume.conversion_factor;
        }
        if (
          this.conversionFactors?.casing_annular_volume_length
            ?.conversion_factor &&
          this.conversionFactors?.casing_annular_volume_length
            ?.conversion_factor != ""
        ) {
          payload.time_data.casingdplength *=
            this.conversionFactors.casing_annular_volume_length.conversion_factor;
          payload.time_data.casinghwdplength *=
            this.conversionFactors.casing_annular_volume_length.conversion_factor;
          payload.time_data.casingdc1length *=
            this.conversionFactors.casing_annular_volume_length.conversion_factor;
        }
        if (
          this.conversionFactors?.casing_annular_volume_capacity
            ?.conversion_factor &&
          this.conversionFactors?.casing_annular_volume_capacity
            ?.conversion_factor != ""
        ) {
          payload.time_data.casingdpannularcapacity *=
            this.conversionFactors.casing_annular_volume_length.conversion_factor;
          payload.time_data.casinghwdpannularcapacity *=
            this.conversionFactors.casing_annular_volume_length.conversion_factor;
        }
        if (
          this.conversionFactors?.casing_annular_volume_volume
            ?.conversion_factor &&
          this.conversionFactors?.casing_annular_volume_volume
            ?.conversion_factor != ""
        ) {
          payload.time_data.cassed_hole_vol *=
            this.conversionFactors.casing_annular_volume_volume.conversion_factor;
        }
        if (
          this.conversionFactors?.open_hole_volume_length?.conversion_factor &&
          this.conversionFactors?.open_hole_volume_length?.conversion_factor !=
            ""
        ) {
          payload.time_data.openholedplength *=
            this.conversionFactors.open_hole_volume_length.conversion_factor;
          payload.time_data.openholehwdplength *=
            this.conversionFactors.open_hole_volume_length.conversion_factor;
          payload.time_data.openholedc2length *=
            this.conversionFactors.open_hole_volume_length.conversion_factor;
          payload.time_data.openholedc1length *=
            this.conversionFactors.open_hole_volume_length.conversion_factor;
        }
        if (
          this.conversionFactors?.open_hole_volume_capacity
            ?.conversion_factor &&
          this.conversionFactors?.open_hole_volume_capacity
            ?.conversion_factor != ""
        ) {
          payload.time_data.ohdpannularcapacity *=
            this.conversionFactors.open_hole_volume_capacity.conversion_factor;
          payload.time_data.hwdpannularcapacity *=
            this.conversionFactors.open_hole_volume_capacity.conversion_factor;
        }
        if (
          this.conversionFactors?.open_hole_volume_volume?.conversion_factor &&
          this.conversionFactors?.open_hole_volume_volume?.conversion_factor !=
            ""
        ) {
          payload.time_data.dpannularvolume *=
            this.conversionFactors.open_hole_volume_volume.conversion_factor;
          payload.time_data.hwdpannularvolume *=
            this.conversionFactors.open_hole_volume_volume.conversion_factor;
          payload.time_data.dc2annularvolume *=
            this.conversionFactors.open_hole_volume_volume.conversion_factor;
          payload.time_data.dc1annularvolume *=
            this.conversionFactors.open_hole_volume_volume.conversion_factor;
        }
        if (
          this.conversionFactors?.surface_line_volume?.conversion_factor &&
          this.conversionFactors?.surface_line_volume?.conversion_factor != ""
        ) {
          payload.surface_data.surface_line_volume *=
            this.conversionFactors.surface_line_volume.conversion_factor;
        }
        if (
          this.conversionFactors?.shut_in_drill_pipe_pressure
            ?.conversion_factor &&
          this.conversionFactors?.shut_in_drill_pipe_pressure
            ?.conversion_factor != ""
        ) {
          payload.kick_data.sidpp *=
            this.conversionFactors.shut_in_drill_pipe_pressure.conversion_factor;
        }
        if (
          this.conversionFactors?.shut_in_casing_pipe_pressure
            ?.conversion_factor &&
          this.conversionFactors?.shut_in_casing_pipe_pressure
            ?.conversion_factor != ""
        ) {
          payload.kick_data.sicp *=
            this.conversionFactors.shut_in_casing_pipe_pressure.conversion_factor;
        }
        if (
          this.conversionFactors?.pit_gain?.conversion_factor &&
          this.conversionFactors?.pit_gain?.conversion_factor != ""
        ) {
          payload.kick_data.pit_gain *=
            this.conversionFactors.pit_gain.conversion_factor;
        }
        if (this.isWellMetrics) {
          payload.time_data.tvd *= 3.28084;
        }
        if (
          this.isWellMetrics &&
          this.conversionFactors?.lot_pressure?.conversion_factor &&
          this.conversionFactors?.lot_pressure?.conversion_factor != ""
        ) {
          payload.pump_data.dpl *=
            this.conversionFactors.lot_pressure.conversion_factor;
        }
        axios.post(URL.HOST_URL.SERVER_URL + "public/killsheet", payload).then(
          (response) => {
            this.isHidden = !this.isHidden;
            console.log(
              "response in vertical killsheet",
              response.data.killsheetdata[0].pressure
            );
            this.result = response.data.result;
            this.Pressure = response.data.killsheetdata[0].pressure;
            this.Strokes = response.data.killsheetdata[0].strokes;
            this.FCP = response.data.killsheetdata[0].FCP;
            this.FCP = parseFloat(this.FCP.toFixed(3));
            this.ICP = response.data.killsheetdata[0].ICP;
            this.ICP = parseFloat(this.ICP.toFixed(3));
            this.Kval = response.data.killsheetdata[0].pressure_reduction;
            this.Kval = parseFloat(this.Kval.toFixed(3));
            this.kfg = response.data.killsheetdata[0].kfg;
            this.kfg = parseFloat(this.kfg.toFixed(3));
            this.kfd = response.data.killsheetdata[0].kfd;
            this.kfd = parseFloat(this.kfd.toFixed(3));
            if (
              this.isWellMetrics &&
              this.conversionFactors?.density?.conversion_factor &&
              this.conversionFactors?.density?.conversion_factor != ""
            ) {
              this.kfd /= this.conversionFactors.density.conversion_factor;
              this.kfd = this.kfd.toFixed(3);
            }
            this.StrokeLength = Object.keys(this.Pressure).length;
            if (
              this.conversionFactors?.mwt_at_test?.conversion_factor &&
              this.conversionFactors?.mwt_at_test?.conversion_factor != ""
            ) {
              // this.kfd /= this.conversionFactors.mwt_at_test.conversion_factor;
              // this.kfg /= this.conversionFactors.mwt_at_test.conversion_factor;
            }
            if (
              this.conversionFactors?.lot_pressure?.conversion_factor &&
              this.conversionFactors?.lot_pressure?.conversion_factor != ""
            ) {
              this.ICP /= this.conversionFactors.lot_pressure.conversion_factor;
              this.ICP = this.ICP.toFixed(3);
              this.FCP /= this.conversionFactors.lot_pressure.conversion_factor;
              this.FCP = this.FCP.toFixed(3);
              this.Kval /=
                this.conversionFactors.lot_pressure.conversion_factor;
              this.Kval = this.Kval.toFixed(3);
              const pressureUntConversion = this.Pressure.map((p) =>
                (
                  p / this.conversionFactors.lot_pressure.conversion_factor
                ).toFixed(3)
              );
              this.Pressure = pressureUntConversion;
            }
            this.buildSVG();
          },
          (error) => {
            this.$toast.error("Something Wrong", {
              position: "top",
              duration: 1500,
            });
          }
        );
      } else {
        this.$toast.error("Fields Should Not be blank", {
          position: "top",
          duration: 1500,
        });
      }
    },
    async getapi(from) {
      await axios
        .post(URL.HOST_URL.SERVER_URL + "public/collect", this.axiosParams)
        .then(
          (resp) => {
            console.log("get api", resp.data);
            this.list = resp.data.time_data;

            this.vuserinp.vuserdata.vbittvd = !isNaN(resp.data.time_data.tvd)
              ? Math.round(resp.data.time_data.tvd * 1e4) / 1e4
              : 0;
            this.vuserinp.vuserdata.vholetvd = !isNaN(resp.data.time_data.btvd)
              ? Math.round(resp.data.time_data.btvd * 1e4) / 1e4
              : 0;
            this.bit_size = !isNaN(resp.data.time_data.bit_size)
              ? Math.round(resp.data.time_data.bit_size * 1e4) / 1e4
              : 0;
            this.vholetvd =
              Math.round(resp.data.time_data.vholetvd * 1e4) / 1e4;
            this.idia = !isNaN(resp.data.well_geometry_data.idia)
              ? Math.round(resp.data.well_geometry_data.idia * 1e4) / 1e4
              : 0;
            this.md = !isNaN(resp.data.well_geometry_data.md)
              ? Math.round(resp.data.well_geometry_data.md * 1e4) / 1e4
              : 0;
            this.tvd = !isNaN(resp.data.well_geometry_data.tvd)
              ? Math.round(resp.data.well_geometry_data.tvd * 1e4) / 1e4
              : 0;
            // from api values
            this.dplength = !isNaN(resp.data.time_data.dplength)
              ? Math.round(resp.data.time_data.dplength * 1e4) / 1e4
              : 0;
            this.dpcapacity = !isNaN(resp.data.time_data.dpcapacity)
              ? Math.round(resp.data.time_data.dpcapacity * 1e4) / 1e4
              : 0;
            // this.dpvolume = !isNaN(resp.data.time_data.dpvolume)
            //   ? Math.round(resp.data.time_data.dpvolume * 1e4) / 1e4
            //   : 0;
            // this.dpvolume = this.dplength * this.dpcapacity;
            this.hwdplength = !isNaN(resp.data.time_data.hwdplength)
              ? Math.round(resp.data.time_data.hwdplength * 1e4) / 1e4
              : 0;
            this.hwdpcapacity = !isNaN(resp.data.time_data.hwdpcapacity)
              ? Math.round(resp.data.time_data.hwdpcapacity * 1e4) / 1e4
              : 0;
            // this.hwdpvolume = !isNaN(resp.data.time_data.hwdpvolume)
            //   ? Math.round(resp.data.time_data.hwdpvolume * 1e4) / 1e4
            //   : 0;
            // this.hwdpvolume = this.hwdplength * this.hwdpcapacity;
            this.dc1length = !isNaN(resp.data.time_data.dc1length)
              ? Math.round(resp.data.time_data.dc1length * 1e4) / 1e4
              : 0;
            this.dc1capacity = !isNaN(resp.data.time_data.dc1capacity)
              ? Math.round(resp.data.time_data.dc1capacity * 1e4) / 1e4
              : 0;
            // this.dc1volume = !isNaN(resp.data.time_data.dc1volume)
            //   ? Math.round(resp.data.time_data.dc1volume * 1e4) / 1e4
            //   : 0;
            // this.dc1volume = this.dc1length * this.dc1capacity;
            this.dc2length = !isNaN(resp.data.time_data.dc2length)
              ? Math.round(resp.data.time_data.dc2length * 1e4) / 1e4
              : 0;
            this.dc2capacity = !isNaN(resp.data.time_data.dc2capacity)
              ? Math.round(resp.data.time_data.dc2capacity * 1e4) / 1e4
              : 0;
            // this.dc2volume = !isNaN(resp.data.time_data.dc2volume)
            //   ? Math.round(resp.data.time_data.dc2volume * 1e4) / 1e4
            //   : 0;
            // this.dc2volume = this.dc2length * this.dc2capacity;
            this.drillstringvolume = !isNaN(
              resp.data.time_data.drillstringvolume
            )
              ? Math.round(resp.data.time_data.drillstringvolume * 1e4) / 1e4
              : 0;
            this.casingdplength = !isNaN(resp.data.time_data.casingdplength)
              ? Math.round(resp.data.time_data.casingdplength * 1e4) / 1e4
              : 0;
            this.casingdpannularcapacity = !isNaN(
              resp.data.time_data.casingdpannularcapacity
            )
              ? Math.round(resp.data.time_data.casingdpannularcapacity * 1e4) /
                1e4
              : 0;
            this.casinghwdplength = !isNaN(resp.data.time_data.casinghwdplength)
              ? Math.round(resp.data.time_data.casinghwdplength * 1e4) / 1e4
              : 0;
            this.casinghwdpannularcapacity = !isNaN(
              resp.data.time_data.casinghwdpannularcapacity
            )
              ? Math.round(
                  resp.data.time_data.casinghwdpannularcapacity * 1e4
                ) / 1e4
              : 0;
            this.casingdc1length = !isNaN(resp.data.time_data.casingdc1length)
              ? Math.round(resp.data.time_data.casingdc1length * 1e4) / 1e4
              : 0;
            this.casingdc2length = !isNaN(resp.data.time_data.casingdc2length)
              ? Math.round(resp.data.time_data.casingdc2length * 1e4) / 1e4
              : 0;
            this.casingdc1annularcapacity = !isNaN(
              resp.data.time_data.casingdc1annularcapacity
            )
              ? Math.round(resp.data.time_data.casingdc1annularcapacity * 1e4) /
                1e4
              : 0;
            this.casingdc2annularcapacity = !isNaN(
              resp.data.time_data.casingdc2annularcapacity
            )
              ? Math.round(resp.data.time_data.casingdc2annularcapacity * 1e4) /
                1e4
              : 0;
            this.openholedplength = !isNaN(resp.data.time_data.openholedplength)
              ? Math.round(resp.data.time_data.openholedplength * 1e4) / 1e4
              : 0;
            this.ohdpannularcapacity = !isNaN(
              resp.data.time_data.ohdpannularcapacity
            )
              ? Math.round(resp.data.time_data.ohdpannularcapacity * 1e4) / 1e4
              : 0;
            // this.dpannularvolume =
            //   this.openholedplength * this.ohdpannularcapacity;
            this.openholehwdplength = !isNaN(
              resp.data.time_data.openholehwdplength
            )
              ? Math.round(resp.data.time_data.openholehwdplength * 1e4) / 1e4
              : 0;
            this.hwdpannularcapacity = !isNaN(
              resp.data.time_data.hwdpannularcapacity
            )
              ? Math.round(resp.data.time_data.hwdpannularcapacity * 1e4) / 1e4
              : 0;
            // this.hwdpannularvolume = !isNaN(
            //   resp.data.time_data.hwdpannularvolume
            // )
            //   ? Math.round(resp.data.time_data.hwdpannularvolume * 1e4) / 1e4
            //   : 0;
            this.openholedc1length = !isNaN(
              resp.data.time_data.openholedc1length
            )
              ? Math.round(resp.data.time_data.openholedc1length * 1e4) / 1e4
              : 0;
            this.dc1annularcapacity = !isNaN(
              resp.data.time_data.dc1annularcapacity
            )
              ? Math.round(resp.data.time_data.dc1annularcapacity * 1e4) / 1e4
              : 0;
            // this.dc1annularvolume = !isNaN(resp.data.time_data.dc1annularvolume)
            //   ? Math.round(resp.data.time_data.dc1annularvolume * 1e4) / 1e4
            //   : 0;
            this.openholedc2length = !isNaN(
              resp.data.time_data.openholedc2length
            )
              ? Math.round(resp.data.time_data.openholedc2length * 1e4) / 1e4
              : 0;
            this.dc2annularcapacity = !isNaN(
              resp.data.time_data.dc2annularcapacity
            )
              ? Math.round(resp.data.time_data.dc2annularcapacity * 1e4) / 1e4
              : 0;
            this.ohdc2annularcapacity = !isNaN(
              resp.data.time_data.ohdc2annularcapacity
            )
              ? Math.round(resp.data.time_data.ohdc2annularcapacity * 1e4) / 1e4
              : 0;
            this.ohdc1annularcapacity = !isNaN(
              resp.data.time_data.ohdc1annularcapacity
            )
              ? Math.round(resp.data.time_data.ohdc1annularcapacity * 1e4) / 1e4
              : 0;

            // this.dc2annularvolume = !isNaN(resp.data.time_data.dc2annularvolume)
            //   ? Math.round(resp.data.time_data.dc2annularvolume * 1e4) / 1e4
            //   : 0;
            this.annularvolume = !isNaN(resp.data.time_data.annularvolume)
              ? Math.round(resp.data.time_data.annularvolume * 1e4) / 1e4
              : 0;
            this.cycle_volume = !isNaN(resp.data.time_data.cycle_volume)
              ? Math.round(resp.data.time_data.cycle_volume * 1e4) / 1e4
              : 0;
            this.active_surface_vol = !isNaN(resp.data.time_data.total_vol)
              ? Math.round(resp.data.time_data.total_vol * 1e4) / 1e4
              : 0;
            this.spm1 = !isNaN(resp.data.time_data.spm1)
              ? Math.round(resp.data.time_data.spm1 * 1e4) / 1e4
              : 0;
            this.spm2 = !isNaN(resp.data.time_data.spm2)
              ? Math.round(resp.data.time_data.spm2 * 1e4) / 1e4
              : 0;
            this.spm3 = !isNaN(resp.data.time_data.spm3)
              ? Math.round(resp.data.time_data.spm3 * 1e4) / 1e4
              : 0;
            this.dmea = !isNaN(resp.data.time_data.dmea)
              ? Math.round(resp.data.time_data.dmea * 1e4) / 1e4
              : 0;
            this.dbtm = !isNaN(resp.data.time_data.dbtm)
              ? Math.round(resp.data.time_data.dbtm * 1e4) / 1e4
              : 0;
            this.time = !isNaN(resp.data.time_data.time)
              ? Math.round(resp.data.time_data.time * 1e4) / 1e4
              : 0;

            try {
              this.time_show = !isNaN(resp.data.time_data.time)
                ? moment(parseInt(resp.data.time_data.time)).format(
                    "MMM DD yyyy HH:MM:SS"
                  )
                : 0;
            } catch (error) {}

            this.annular_casing_vol = !isNaN(
              resp.data.time_data.annular_casing_vol
            )
              ? Math.round(resp.data.time_data.annular_casing_vol * 1e4) / 1e4
              : 0;
            // this.tot_active_fluid_sys = !isNaN(
            //   resp.data.time_data.tot_active_fluid_sys
            // )
            //   ? Math.round(resp.data.time_data.tot_active_fluid_sys * 1e4) / 1e4
            //   : 0;
            this.pumpoutput = !isNaN(resp.data.time_data.pumpoutput)
              ? Math.round(resp.data.time_data.pumpoutput * 1e4) / 1e4
              : 0;

            this.incl_data = !isNaN(resp.data.time_data.pumpoutput)
              ? Math.round(resp.data.time_data.incl_data * 1e4) / 1e4
              : this.inclination;

            this.inclination = this.incl_data;
            this.section_type = !isNaN(
              resp.data.well_geometry_data.section_type
            )
              ? Math.round(resp.data.well_geometry_data.section_type * 1e4) /
                1e4
              : 0;

            // this.totallength =
            //   this.dplength + this.hwdplength + this.dc1length + this.dc2length;
            this.totalcapacity =
              this.dpcapacity +
              this.hwdpcapacity +
              this.dc1capacity +
              this.dc2capacity;
            if (this.inclination < 0) {
              this.$toast.error("Inclination  is in negative", {
                duration: 1000,
                position: "top",
              });
              this.incldatanull = false;
              this.inclination = "";
              this.incl_data = "";
            }
          },
          (error) => {
            this.$toast.error("Something Wrong", {
              position: "top",
              duration: 1500,
            });
            this.vuserinp.vuserdata.vbittvd = "";
            this.vuserinp.vuserdata.vholetvd = "";
            this.bit_size = "";
            this.vholetvd = "";
            this.vholetmd = "";
            this.vholesize = "";
            this.idia = "";
            this.md = "";
            this.tvd = "";
            this.dplength = "";
            this.dpcapacity = "";
            this.hwdplength = "";
            this.hwdpcapacity = "";
            this.dc1length = "";
            this.dc1capacity = "";
            this.dc1volume = "";
            this.dc2length = "";
            this.dc2capacity = "";
            this.dc2volume = "";
            this.drillstringvolume = "";
            this.casingdplength = "";
            this.casingdpannularcapacity = "";
            this.casinghwdplength = "";
            this.casinghwdpannularcapacity = "";
            this.casingdc1length = "";
            this.casingdc1annularcapacity = "";
            this.openholedplength = "";
            this.ohdpannularcapacity = "";
            this.dpannularvolume = "";
            this.openholehwdplength = "";
            this.hwdpannularcapacity = "";
            this.hwdpannularvolume = "";
            this.openholedc1length = "";
            this.dc1annularcapacity = "";
            this.dc1annularvolume = "";
            this.openholedc2length = "";
            this.dc2annularcapacity = "";
            this.dc2annularvolume = "";
            this.openholevolume = "";
            this.annularvolume = "";
            this.cycle_volume = "";
            this.active_surface_vol = "";
            this.spm1 = "";
            this.spm2 = "";
            this.spm3 = "";
            this.dmea = "";
            this.dbtm = "";
            this.time = "";
            this.string_strokes = "";
            this.annular_casing_vol = "";
            this.tot_active_fluid_sys = "";
            this.totallength = "";
            this.totalcapacity = "";
            this.totalcasingdplength = "";
            this.totalcasingdpannularcapacity = "";
            this.totopenholelength = "";
            this.totopenholecapacity = "";
          }
        );
      if (this.incl_data < 10 && this.incl_data >= 0) {
        this.checked = false;
      } else if (this.incl_data == "" || this.incl_data == null) {
        this.incldatanull = false;
      } else {
        this.checked = true;
      }
    },
    takeOffSocket() {
      this.liveDisabled = false;
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: this.logId,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },
    // This is to create the SVG of the line chart graph for Vertical
    async buildSVG() {
      var margin = {
          top: 10,
          right: this.width * 0.3,
          bottom: 50,
          left: 85,
        },
        width = this.width - margin.left - margin.right - 370,
        height = this.height - margin.top - margin.bottom - 150;
      let labelFontSize = 12;
      let tooltipFontSize = 10;
      //create base svg
      svg = select("#verticalKillsheet" + this.widgetId);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let verKillSheet = document.createElement("div");
        verKillSheet.setAttribute("id", "verticalKillsheet" + this.widgetId);
      }
      svg = select("#verticalKillsheet" + this.widgetId)
        .append("svg")
        .attr("width", width / 2 + margin.left + margin.right - 60)
        .attr("height", height + margin.top + margin.bottom)
        .attr("id", "verticalKillsheetSVG")
        .append("g")
        .attr("id", "verticalKillsheet1")
        .attr("transform", `translate(${margin.left + 20}, ${margin.top + 9})`);

      //create axis
      var X_array = this.Strokes,
        Y_array = this.Pressure;
      this.maxX = max(Object.keys(X_array), function (o) {
        return X_array[o];
      });
      this.maxY = max(Object.keys(Y_array), function (o) {
        return Y_array[o];
      });
      var x = scaleLinear().domain([this.minX, this.maxX]).range([0, width]);
      var y = scaleLinear().domain([this.minY, this.maxY]).range([height, 0]);
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();
      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();
      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);
      // color: var(--textColor)
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", "var(--textColor)")
        .style("fill", "")
        .attr("stroke-opacity", 0.6)
        .call(xAxisGrid);
      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", "var(--textColor)")
        .attr("stroke-opacity", 0.6)
        .call(yAxisGrid);
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg
        .selectAll(".xAxis")
        .selectAll("text")
        .call(function (t) {
          t.each(function (d) {
            var self = select(this);
            var num = +self.text().replaceAll(",", "");
            var lbTodisp =
              Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            self.text(lbTodisp);
          });
        }),
        svg.selectAll(".xAxis").selectAll("text").attr("fill", this.yAxisLabel);
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
        svg
        .selectAll(".yAxis")
        .selectAll("text")
        .call(function (t) {
          t.each(function (d) {
            var self = select(this);
            var num = +self.text().replaceAll(",", "");
            var lbTodisp =
              Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            self.text(lbTodisp);
          });
        }),  
      svg.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2 + 30)
        .attr("y", height + 40)
        .style("fill", "var(--textColor)")
        .style("font-size", labelFontSize)
        .text("Strokes (stks)".toUpperCase());

      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr("dy", "-3.9em")
        .attr("dx", -height / 2 + 30)
        .attr("transform", "rotate(-90)")
        .style("fill", "var(--textColor)")
        .style("font-size", labelFontSize)
        .text(
          `pressure ${
            this.conversionFactors?.lot_pressure?.from_unit &&
            this.conversionFactors?.lot_pressure?.from_unit != ""
              ? this.conversionFactors.lot_pressure.from_unit
              : this.isWellMetrics
              ? this.mappingDetails.lot_pressure.METRIC
              : this.mappingDetails.lot_pressure.IMPERIAL
          }`.toUpperCase()
        );

      var x_array = this.Strokes,
        y_array = this.Pressure;
      var lowest = max(Object.keys(x_array), function (o) {
        return x_array[o];
      });
      var xy = [];
      for (var i = 0; i < this.StrokeLength; i++) {
        xy.push({ x: x_array[i], y: y_array[i] });
      }
      var slice = line()
        .x(function (d, i) {
          return x(d.x);
        })
        .y(function (d, i) {
          return y(d.y);
        });
      svg
        .append("path")
        .attr("d", slice(xy))
        .attr("class", "line")
        .style("stroke", "var(--textColor)")
        .attr("fill", "none");
      console.log("XY ", xy);
    },
    async buildHoriSVG() {
      var margin = {
          top: 10,
          right: this.width * 0.3,
          bottom: 50,
          left: 85,
        },
        width = this.width - margin.left - margin.right - 370,
        height = this.height - margin.top - margin.bottom - 150;
      let labelFontSize = 12;
      let tooltipFontSize = 10;
      //create base svg
      svg = select("#HoriKillsheet" + this.widgetId);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let HoriKillsheet = document.createElement("div");
        HoriKillsheet.setAttribute("id", "HoriKillsheet" + this.widgetId);
      }
      svg = select("#HoriKillsheet" + this.widgetId)
        .append("svg")
        .attr("width", width / 2 + margin.left + margin.right - 60)
        .attr("height", height + margin.top + margin.bottom)
        .attr("id", "HoriKillsheetSVG")
        .append("g")
        .attr("id", "HoriKillsheet1")
        .attr("transform", `translate(${margin.left + 20}, ${margin.top + 9})`);

      //create axis
      var X_array = this.Strokes,
        Y_array = this.Pressure;
      this.maxX = max(Object.keys(X_array), function (o) {
        return X_array[o];
      });
      this.maxY = max(Object.keys(Y_array), function (o) {
        return Y_array[o];
      });
      var x = scaleLinear().domain([this.minX, this.maxX]).range([0, width]);
      var y = scaleLinear().domain([this.minY, this.maxY]).range([height, 0]);
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();
      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();
      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", "var(--textColor)")
        .style("fill", "")
        .attr("stroke-opacity", 0.6)
        .call(xAxisGrid);
      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", "var(--textColor)")
        .attr("stroke-opacity", 0.6)
        .call(yAxisGrid);
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg
        .selectAll(".xAxis")
        .selectAll("text")
        .call(function (t) {
          t.each(function (d) {
            var self = select(this);
            var num = +self.text().replaceAll(",", "");
            var lbTodisp =
              Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            self.text(lbTodisp);
          });
        })
        .attr("fill", "var(--textColor)");
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
      svg
        .selectAll(".yAxis")
        .selectAll("text")
        .call(function (t) {
          t.each(function (d) {
            var self = select(this);
            var num = +self.text().replaceAll(",", "");
            var lbTodisp =
              Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            self.text(lbTodisp);
          });
        }),   
      svg
        .selectAll(".yAxis")
        .selectAll("text")
        .attr("fill", "var(--textColor)");
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2 + 30)
        .attr("y", height + 40)
        .style("fill", "var(--textColor)")
        .style("font-size", labelFontSize)
        .text("Strokes (stks)".toUpperCase());
      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr("dy", "-3.9em")
        .attr("dx", -height / 2 + 30)
        .attr("transform", "rotate(-90)")
        .style("fill", "var(--textColor)")
        .style("font-size", labelFontSize)
        .text(
          `pressure ${
            this.conversionFactors?.lot_pressure?.from_unit &&
            this.conversionFactors?.lot_pressure?.from_unit != ""
              ? this.conversionFactors.lot_pressure.from_unit
              : this.isWellMetrics
              ? this.mappingDetails.lot_pressure.METRIC
              : this.mappingDetails.lot_pressure.IMPERIAL
          }`.toUpperCase()
        );

      var x_array = this.Strokes,
        y_array = this.Pressure;
      var lowest = max(Object.keys(x_array), function (o) {
        return x_array[o];
      });
      var xy = [];
      for (var i = 0; i < this.StrokeLength; i++) {
        xy.push({ x: x_array[i], y: y_array[i] });
        console.log("xy vals ", xy);
      }
      var slice = line()
        .x(function (d, i) {
          return x(d.x);
        })
        .y(function (d, i) {
          return y(d.y);
        });

      svg
        .append("path")
        .attr("d", slice(xy))
        .attr("class", "line")
        .style("stroke", "var(--textColor)")
        .attr("fill", "none");
    },
  },
  beforeUnmount() {
    this.data = [];
    this.completeData = [];
    this.takeOffSocket();
  },
  async mounted() {
      this.inclination=8;
        this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display &&
        this.id
      ) {
        if( mutation.payload.wellBoreName=="TY RANCH WEST 1LS 1LS6"){
          this.checked=true;
        }else{
          this.checked=false;
        }
  }})

    // this.tempWidgetId = this.backgroundId || this.tempWidgetId;
    // this.customer = this.$store.state.data.customer;
    // let dtls = this.$store.state.disp.displays[this.displayId];
    // this.$store.subscribe(async (mutation, state) => {
    //   if (
    //     mutation.type == "disp/setDisplay" &&
    //     this.displayId == mutation.payload.display &&
    //     this.id
    //   ) {
    //     this.refreshUnitkey += 87;
    //     this.customer = this.$store.state.data.customer;
    //     this.takeOffSocket();
    //     this.data = [];
    //     this.completeData = [];
    //     console.log("well name", mutation.payload);
    //     this.wellId = mutation.payload.wellId;
    //     this.wellboreId = mutation.payload.wellboreId;
    //     this.logId = mutation.payload.logId;
    //     this.logType = mutation.payload.logType;
    //     this.logTypeId = mutation.payload.logTypeId;
    //     this.status = mutation.payload.status;
    //     this.isWellMetrics = mutation.payload.unit_conversion;
    //     this.time_zone = mutation.payload.time_zone || "CST6CDT";

    //     console.log("~~~ time zone alert : mounted ", this.time_zone);
    //     moment.tz.setDefault(this.time_zone);
    //     let current = new Date();
    //     this.date =
    //       current.getFullYear() +
    //       "-" +
    //       String(current.getMonth() + 1).padStart(2, "0") +
    //       "-" +
    //       String(current.getDate()).padStart(2, "0") +
    //       " " +
    //       String(current.getHours()).padStart(2, "0") +
    //       ":" +
    //       String(current.getMinutes()).padStart(2, "0") +
    //       ":" +
    //       String(current.getSeconds()).padStart(2, "0");
    //     this.max = mutation.payload.max;
    //     try {
    //       this.$store.state.rect.rects.map((data) => {
    //         if (data.widgetId == this.widgetId) delete data.unitConversion;
    //       });
    //       this.time_show = moment(this.max).format("MMM DD yyyy HH:MM:SS");
    //       // this.refresh();
    //     } catch (error) {}
    //     if (
    //       this.wellId !== null &&
    //       this.wellboreId !== null &&
    //       this.customer !== null
    //     ) {
    //       await this.getapi("subscribe");
    //     }
    //   }
    // });

    // console.log("well name", dtls);
    // if (
    //   dtls != null &&
    //   dtls.wellId != null &&
    //   dtls.wellboreId != null &&
    //   dtls.wellboreState != null
    // ) {
    //   this.max = dtls.max;
    //   this.wellId = dtls.wellId;
    //   this.wellboreId = dtls.wellboreId;
    //   this.isWellMetrics = dtls.unit_conversion;
    //   try {
    //     this.time_show = moment(this.max).format("MMM DD yyyy HH:MM:SS");
    //   } catch (error) {}
    //   if (
    //     this.wellId !== null &&
    //     this.wellboreId !== null &&
    //     this.customer !== null
    //   ) {
    //     await this.getapi("mounted");
    //   }
    // }
    // if(this.isWellMetrics){
    //   let pressureCategory = await helperServices.getUnitCategories('Pressure');
    //   // let pressureCategory = await helperServices.getUnitCategories('MW-Density gradients');
    //   // let pressureCategory = await helperServices.getUnitCategories('Length');
    //   db.unitfactors
    //           .where("units_category_id")
    //           .equals(this.units.units_category_id)
    //           .each((fact) => {

    //             if (fact.from_unit == from && fact.to_unit ==to) {
    //               console.log("unit_conversion_factor_change", fact);
    //               // if (this.isCustomEvents) {
    //               //   if(this.reverse){
    //               //     fact.to_unit=fact.from_unit;
    //               //     fact.from_unit=fact.to_unit;
    //               //   }
    //               //   this.$emit('change_unit_handler',fact);
    //               // } else {
    //                 this.$store.dispatch("rect/changeUnitConversionFactor", {
    //                   displayId: this.displayId,
    //                   widgetId: this.widgetId,
    //                   value: fact.conversion_factor,
    //                   unit: fact.to_unit,
    //                 });
    //              // }
    //             }
    //           });
    // }
  },
};
</script>
<style>


.firtPart-in-result {
  width: 80%;
  height: fit-content;
  /* padding: 1px; */
  margin-right: 5px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.w-14 {
  width: 3.5rem;
}

h4 {
  text-align: left;
}

.pump .pmpinp {
  background-color: black;
  padding-top: 3px;
  padding-bottom: 3px;
}

.pump input:focus-visible {
  outline: none !important;
}

.pumpdiv::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.pumpdiv::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.pumpdiv::-webkit-scrollbar-thumb {
  background: #888;
}

.pumpdiv::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-xxxs {
  font-size: 7px;
}

.text-xxs {
  font-size: 9px;
  text-transform: uppercase;
}

.text-xxxxs {
  font-size: 9px;
}

.text-xxxxxs {
  font-size: 8px;
}

.text-sx {
  font-size: 7px;
}

/* Toggle Button Design Start */
.colr {
  color: #61cba1;
}

.checkbox-labels {
  /* background-color: #7f7d7d; */
  background-color: var(--toggleSliderBg);
  width: 40px;
  height: 18px;
  border-radius: 50px;
  position: relative;
  padding: 7px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-labels .ball {
  background-color: #fff;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 3px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkboxs:checked + .checkbox-labels .ball {
  transform: translateX(20px);
}

.poup button {
  padding-right: 13px;
}

/* Toggle Button Design End */
/* Conclusion Button Design Start */
.conclusion_input {
  /* background: var(--colorBoxPopUpBg); */
  background: var(--widgetBg);
  color: var(--textColor);
}

.text_theme {
  color: var(--textColor);
}

a.execute-btn {
  position: relative;
  display: inline-block;
  padding: 3px 10px;
  /* color: #03e9f4; */
  color: var(--activeTextColor);
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 2px;
}

a.execute-btn:hover {
  /* background: #03e9f4; */
  background: var(--activeTextColor);
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px var(--activeTextColor)00, 0 0 25px var(--activeTextColor)00, 0 0 50px var(--activeTextColor)45,
    0 0 100px var(--activeTextColor)00;
}

a.execute-btn span {
  position: absolute;
  display: block;
}

a.execute-btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--activeTextColor));
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

a.execute-btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--activeTextColor));
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

a.execute-btn span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, var(--activeTextColor));
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

a.execute-btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, var(--activeTextColor));
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

/* Conclusion Button Design End */
/* Pop Up CSS Start*/

.poup,
.hpoup {
  z-index: 999;
  position: absolute;
  top: 35%;
  left: 50%;
  background: var(--widgetBg);
  color: var(--textColor);
  width: 90%;
  height: 85%;
  transform: translate(-50%, -40%);
  box-shadow: 3px 0px 12px 1px #e1f5fe;
}

/* Pop Up CSS End*/
.scroll {
  max-height: 350px;
  height: 350px;
  display: inline-block;
  overflow: scroll;
}

.scroll thead tr {
  position: sticky;
  top: 0;
  border: 1px solid #fff;
  box-shadow: 0 0 2px 2px rgb(255 255 255 / 40%);
  background-color: #000;
}

.cid {
  height: 355px;
}

.pmpselc {
  width: 110px !important;
  height: 18.75px !important;
  padding: 0 !important;
  border-width: 1px !important;
  border-top: 1px solid !important;
  padding-left: 4px !important;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 10px;
}

.precellslct {
  width: 100% !important;
  height: 16px !important;
  padding: 0 !important;
  border: 0 !important;
  padding: 2px 0px !important;
  margin-left: 0px;
  display: flex;
  align-items: start;
  font-size: 7.5px;
  color: #11e111 !important;
}

.precellslct.color-red {
  color: #ff5353 !important;
}

.chekbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 15px;
  width: 11px;
}

.checkmark {
  position: absolute;
  top: 6px;
  left: 12px;
  height: 11px;
  cursor: pointer;
  width: 11px;
  background-color: #000;
  border: 1px solid #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input.chekbox:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 3px;
  top: -1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}

.chec {
  width: 50px !important;
  padding-left: 3px !important;
}

.lbl {
  display: inline;
  padding: 1px 10px;
}

.color-red {
  color: #ff5353;
}

.color-green {
  color: #11e111;
}

.color-orange {
  color: orange;
}

.color-drkorange {
  color: orange;
}

.color-purple {
  color: #fc2bfc;
}

.color-blue {
  color: #f5cbfa;
}

.color-pink {
  color: pink;
}

.border-green-300 {
  border-color: #11e111;
}

.slpmprate1 .checkmark,
.slpmprate1,
.slpmprate2 .checkmark,
.slpmprate2,
.slpmprate3 .checkmark,
.slpmprate3 {
  opacity: 0.5;
  pointer-events: none;
}

.dispokbtn {
  padding: 3px 15px !important;
  /* border: 1px solid #03e9f4 !important; */
  border: 1px solid var(--activeTextColor) !important;
  outline: none !important;
  /* color: #03e9f4 !important; */
  color: var(--activeTextColor) !important;
  /* 20a4ab */
  max-width: 125px;
}

/* Displacement claculation butn css */
.dispcalcbtn {
  padding: 3px 8px !important;
  /* border: 1px solid #03e9f4 !important; */
  border: 1px solid var(--activeTextColor) !important;
  outline: none !important;
  /* color: #03e9f4 !important; */
  color: var(--activeTextColor) !important;
  max-width: 125px;
}

.refresh {
  padding: 2px 8px !important;
  /* border: 1px solid #03e9f4 !important; */
  border: 1px solid var(--activeTextColor) !important;
  outline: none !important;
  /* color: #03e9f4 !important; */
  color: var(--activeTextColor) !important;
  max-width: 125px;
}

.incdata {
  z-index: 999;
  position: absolute;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.dispcalcnclbtn {
  padding: 3px 8px !important;
  border: 1px solid #f40303 !important;
  outline: none !important;
  color: #f40303 !important;
  max-width: 135px;
  margin-right: 10px;
}

.dispcalcpopupcl {
  z-index: 999;
  position: absolute;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.dispop {
  position: absolute;
  top: 30%;
  left: 50%;
  /* background: var(--colorBoxPopUpBg); */
  background: var(--widgetBg);
  color: var(--textColor);
  transform: translate(-50%, -40%);
}

.dispdata,
.horiDispdata {
  opacity: 0.5;
  pointer-events: none;
}

.minimize {
  visibility: hidden;
}

.minimizer {
  /* padding: 13px 0px 0 10px; */
  width: 40px;
  height: 40px;
  text-align: center;
  background: var(--activeTextColor);
  color: white;
  /* border-radius: 100% 0% 100% 0% / 100% 100% 0% 0%; */
  position: fixed !important;
  bottom: 50px;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  overflow: hidden;
}

.tick text {
  /* font-size: 8px !important; */
}

.pump th {
  /* font-weight: 500 !important; */
}

.Child_Of_sendMail_modal {
  width: 60%;
  height: 65%;
  margin: auto;
  border-radius: 2px;
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
}

.sendMail_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(28, 29, 31, 0.7000000000000001);
  display: flex;
  z-index: 999999;
  align-items: center;
}
td input {
  background-color: var(--widgetBg) !important;
}
.killsheet_main label + input {
  background-color: transparent !important;
}
.killsheet_main select,
option {
  background-color: var(--widgetBg) !important;
}
.colorOfKillTool {
  color: white !important;
  background: black !important;
}
.textColorClass {
  color: var(--textColor);
}
.border_color_theme_ctx {
  border-color: var(--textColor);
}
.killsheet_widget_unit_select select {
  width: 3.5rem;
  background-color: var(--navBar2Bg);
  color: var(--textColor);
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
  border-width: 1px !important;
  border-top: 1px solid rgba(228, 228, 231, var(--tw-border-opacity)) !important;
  padding: 0px 4px;
  height: 22.75px !important;
  /* border-top-color:rgba(228, 228, 231, var(--tw-border-opacity)); */
  /* border-radius: 0.25rem; */
  /* height: 22px !important; */
}
.killsheet_widget_unit_select_table select {
  width: 3.5rem;
  background-color: var(--navBar2Bg);
  color: var(--textColor);
  /* border-color: rgba(228, 228, 231, var(--tw-border-opacity)); */
  /* border-width: 1px !important; */
  /* border-top: 1px solid rgba(228, 228, 231, var(--tw-border-opacity)) !important; */
  padding: 0px 4px;
  /* border-top-color:rgba(228, 228, 231, var(--tw-border-opacity)); */
  /* border-radius: 0.25rem; */
  /* height: 22px !important; */
}
.killsheet_widget_unit_select select:focus {
  outline: none;
}
.killsheet_widget_unit_select select:focus {
  outline: none;
}
.killsheet_widget_unit_select {
  width: 3.5rem;
}
.out_side_table_input {
  background-color: transparent !important;
}
.killsheet_main .text-sx,
.killsheet_main .text-xxxs {
  font-size: 10px;
}
.killsheet_main .text-xxs {
  font-size: 12px;
}
.killsheet_main input.text-xxxs{
  font-weight: inherit;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
</style>
